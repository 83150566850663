interface Props extends React.HTMLAttributes<HTMLDivElement> {
  key?: string;
  questionText: string;
  required?: boolean;
}

export const QuestionContainer = ({ questionText, required, children, ...rest }: Props) => {
  return (
    <div {...rest}>
      <p className='font-semibold sm:text-lg text-base mb-2.5'>
        {questionText} {required && <span className='text-red text-sm'>*</span>}
      </p>
      <div className={'grid grid-cols-1 gap-2'}>{children}</div>
    </div>
  );
};
