import Title from 'components/Title';
import { useLocation } from 'react-router-dom';

const SubmitSuccessPage = () => {
  const location = useLocation();
  const { state } = location;

  return (
    <div className='flex h-screen flex-col py-10 items-center'>
      <div className='w-[80%] md:w-full'>
        <Title title={state?.title} />
      </div>
      <div className='rounded-lg bg-white sm:p-8 p-4 w-[60%] h-fit my-10'>
        <div className='flex flex-col justify-center gap-2 mb-4'>
          <p className='font-semibold sm:text-3xl text-2xl'>提出完了</p>
          <p className='sm:text-xl text-lg'>回答は正常に送信されました。ご回答ありがとうございました。</p>
        </div>
      </div>
    </div>
  );
};

export default SubmitSuccessPage;
