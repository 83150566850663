import { OptionItem } from '../../../../types/components/common/select';
import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { DailyActivitySection, RespondentFormData } from '../../../../types/asq';
import { ComponentProps } from 'react';
import { FlexColumnItemContainer } from '../FlexColumnItemContainer';
import { DailyActivityQuestionContainer } from './DailyActivityQuestionContainer';

interface Props extends ComponentProps<typeof FlexColumnItemContainer> {
  section: DailyActivitySection;
  sectionIndex: number;
  options: OptionItem[];
  setValue: UseFormSetValue<RespondentFormData>;
  currentFormData: RespondentFormData;
  required?: boolean;
  formErrors: FieldErrors<RespondentFormData>;
}

export const DailyActivitySectionContainer = ({
  section,
  sectionIndex,
  currentFormData,
  required,
  setValue,
  options,
  formErrors,
  ...rest
}: Props) => {
  return (
    <FlexColumnItemContainer {...rest}>
      <p className='font-semibold'>{section.title}</p>
      {section.note && <p className='text-sm text-gray-500'>{section.note}</p>}
      {section.questions.map((question, questionIndex) => {
        return (
          <DailyActivityQuestionContainer
            key={`section-${section.id}-question-${question.id}`}
            note={question.note}
            image={question.image}
            questionText={question.question_text}
            has_additional_question={question.has_additional_question}
            questionIndex={questionIndex + 1}
            options={options}
            setValue={setValue}
            required={required}
            nestedFieldKey={`sections.${sectionIndex}.responses.${questionIndex}`}
            radioValue={
              currentFormData.daily_activity_response_group.sections[sectionIndex]?.responses[questionIndex]?.choice
            }
            textValue={
              currentFormData.daily_activity_response_group.sections[sectionIndex]?.responses[questionIndex]
                ?.additional_answer
            }
            formErrors={formErrors}
            className={'flex flex-col gap-4'}
          />
        );
      })}
    </FlexColumnItemContainer>
  );
};
