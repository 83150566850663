import { ComponentProps, FC, Fragment, useMemo } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

import { OptionItem } from '../../types/components/common/select';

interface SelectProps extends ComponentProps<typeof Listbox> {
  label?: string;
  value?: string;
  options: OptionItem[];
  errorMessage?: string;
}

const Select: FC<SelectProps> = ({ label, value, onChange, options, errorMessage }) => {
  const selectedOption = useMemo(() => {
    const result = options.find((opt) => opt.value === value);
    if (!result) return { text: '-', value: '' };
    return result;
  }, [options, value]);

  return (
    <div>
      {label && <label className='mb-2 block text-base font-medium text-black'>{label}</label>}
      <Listbox value={selectedOption} onChange={onChange}>
        <div className='relative'>
          <Listbox.Button
            className={`relative w-full appearance-none rounded-md border border-stroke bg-white py-2 px-5 text-black text-left outline-none transition disabled:cursor-default disabled:bg-gray-2
          ${
            errorMessage
              ? 'border-red focus:border-red active:border-red'
              : 'border-stroke focus:border-primary active:border-primary'
          }
          `}
          >
            <span className='block truncate pr-2'>{selectedOption.text}</span>
            <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
              <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
            <Listbox.Options className='absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none '>
              {options.map((option, index) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-blue-50 text-primary' : 'text-dark'
                    }`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {option.text}
                      </span>
                      {selected ? (
                        <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-primary'>
                          <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {errorMessage && <p className='mt-2 text-sm text-red'>{errorMessage}</p>}
    </div>
  );
};

export default Select;
