import { RadioGroupField } from '../../FormField/RadioGroupField';
import { OptionItem } from '../../../../types/components/common/select';
import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { RespondentFormData } from '../../../../types/asq';
import React, { useMemo } from 'react';
import { TextAreaInputField } from '../../FormField/TextAreaInputField';

interface Props
  extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'children'> {
  questionText: string;
  questionIndex: number;
  image: string | null;
  has_additional_question: boolean;
  note: string;
  options: OptionItem[];
  setValue: UseFormSetValue<RespondentFormData>;
  nestedFieldKey: string;
  radioValue?: number;
  textValue?: string;
  required?: boolean;
  formErrors: FieldErrors<RespondentFormData>;
}

export const DailyActivityQuestionContainer = ({
  questionText,
  questionIndex,
  image,
  note,
  has_additional_question,
  required,
  setValue,
  options,
  radioValue,
  textValue,
  nestedFieldKey,
  formErrors,
  ...rest
}: Props) => {
  /**
   * 値が数字で管理されている場合もあるので、文字列に変換しておく
   */
  const currentRadioValue = useMemo(() => {
    return radioValue === null || radioValue === undefined ? '' : radioValue.toString();
  }, [radioValue]);

  return (
    <div {...rest}>
      <div className='flex items-center gap-2 mb-2'>
        <span className='p-2.5 bg-gray-light text-sm sm:text-base font-semibold rounded-lg ring-2 ring-gray-medium'>
          Q{questionIndex}
        </span>
        <span className={`font-semibold sm:text-xl text-lg`}>{questionText}</span>
        {required && <span className='text-red text-sm'>*</span>}
      </div>
      {note && <p className='text-sm text-gray-500'>{note}</p>}

      {image && (
        <div className='mt-1'>
          <img src={image} alt='question' className='w-1/4 h-auto object-contain' loading={'lazy'} />
        </div>
      )}

      <RadioGroupField
        options={options}
        setValue={setValue}
        value={currentRadioValue}
        fieldKey={`daily_activity_response_group`}
        nestedFieldKey={`${nestedFieldKey}.choice`}
        formErrors={formErrors}
      />

      {has_additional_question && (
        <div className='mt-4'>
          <p className='font-semibold'>追加質問</p>
          <TextAreaInputField
            setValue={setValue}
            formErrors={formErrors}
            fieldKey={'daily_activity_response_group'}
            nestedFieldKey={`${nestedFieldKey}.additional_answer`}
            value={textValue}
          />
        </div>
      )}
    </div>
  );
};
