import { FC } from 'react';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  value: string;
  label?: string;
  // eslint-disable-next-line no-unused-vars
  spinHandler: (type: 'increase' | 'decrease') => void;
  errorMessage?: string;
  className?: string;
  rows?: number;
}

const NumberInput: FC<InputProps> = ({
  value,
  onChange,
  onBlur,
  label,
  placeholder,
  errorMessage,
  className,
  spinHandler
}) => {
  return (
    <div className={className}>
      {label && <label className='mb-2 text-left block text-base font-medium text-black'>{label}</label>}
      <div className='relative'>
        <div className='flex'>
          <button
            type='button'
            onClick={() => spinHandler('decrease')}
            className='bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-r-0 border-gray-300 rounded-s-lg p-3 h-full focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none'
          >
            <MinusIcon width={16} height={16} />
          </button>
          <input
            type='number'
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            className={`hide-spin w-full bg-white border py-2 px-5 text-black outline-none transition disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2 ${
              errorMessage
                ? 'border-red focus:border-red active:border-red'
                : 'border-stroke focus:border-primary active:border-primary'
            }`}
          />
          <button
            type='button'
            onClick={() => spinHandler('increase')}
            className='bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-l-0 border-gray-300 rounded-e-lg p-3 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none'
          >
            <PlusIcon width={16} height={16} />
          </button>
        </div>
      </div>
      {errorMessage && <p className='mt-2 text-sm text-red'>{errorMessage}</p>}
    </div>
  );
};

export default NumberInput;
