import { ComponentProps, FC } from 'react';
import { RadioGroup as HLRadioGroup } from '@headlessui/react';
import { CheckIcon } from './Icon';

import { OptionItem } from '../../types/components/common/select';

interface RadioGroupProps extends ComponentProps<typeof HLRadioGroup> {
  label?: string;
  options: OptionItem[];
  errorMessage?: string;
}

const RadioGroup: FC<RadioGroupProps> = ({ label, options, value, onChange, errorMessage }) => {
  return (
    <HLRadioGroup value={value} onChange={onChange}>
      <HLRadioGroup.Label>{label}</HLRadioGroup.Label>
      {options.map((option) => (
        <HLRadioGroup.Option
          key={option.value}
          value={option.value}
          className={`py-4 relative flex cursor-pointer focus:outline-none bg-white`}
        >
          {({ checked }) => (
            <div className='flex w-full items-center gap-4'>
              {checked ? (
                <CheckIcon checked className='fill-primary stroke-primary' />
              ) : (
                <CheckIcon className='stroke-gray-medium/70' />
              )}
              <HLRadioGroup.Label as='p' className={`text-lg font-normal ${checked ? 'text-primary' : 'text-black'}`}>
                {option.text}
              </HLRadioGroup.Label>
            </div>
          )}
        </HLRadioGroup.Option>
      ))}
      {errorMessage && <p className='mt-2 text-sm text-red'>{errorMessage}</p>}
    </HLRadioGroup>
  );
};

export default RadioGroup;
