import { useEffect, useRef } from 'react';

export const useRefEffect = (ref: React.RefObject<HTMLElement>, callback: Function) => {
  const prevValue = useRef(ref.current);

  useEffect(() => {
    if (ref.current !== prevValue.current) {
      callback(ref.current);
      prevValue.current = ref.current;
    }
  });
};
