import Chart, { defaultColors } from 'components/Chart';
import LoadingWrapper from 'components/Loading/LoadingWrapper';
import Title from 'components/Title';
import { getCities, getStatistic } from 'helpers/backend-helper';
import * as chartHelper from 'helpers/chart-helper';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import MapComponent from 'components/Map';
import { UserIcon } from '@heroicons/react/24/solid';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import { ChartData, Comment, DictionaryItem } from 'types';
import {
  GRAY_ZONE_STRESS_LEVEL,
  GRAY_ZONE_TIME,
  STAFF_MEMBERS,
  TEACHER_AGE,
  YEARS_LAST_AVERAGE,
  YEARS_OF_EXPERENCE
} from 'types/constants';
import { useRefEffect } from 'helpers/useRefEffect';
import { monthAge } from 'helpers/utils';
import Select from 'components/Common/Select';
import { toast } from 'react-toastify';
import { OptionItem } from '../../types/components/common/select';

const SurveyReportPage = () => {
  const [chartData, setChartData] = useState<ChartData>();
  const [isLoading, setIsLoading] = useState(false);
  const [schools, setSchools] = useState<Comment[]>([]);
  const [cities, setCities] = useState<OptionItem[]>([]);
  const [selectedComment, setSelectedComment] = useState<Comment>();
  const commentRef = useRef<HTMLDivElement>(null);
  const [columns, setComlums] = useState<number>(0);
  const commentItemSize = 140;
  const commentItemSpace = 16;

  const mapLabel = (value: number, label: DictionaryItem[]) => {
    const foundLabel = label.find((item) => value > item.key - 1 && value <= item.key);
    return foundLabel ? foundLabel.value : '';
  };

  const formatterCustom = (value: number, key: any, isAllItem: boolean) => {
    const index = isAllItem ? key.seriesIndex : chartHelper.schoolEvaluation.indexOf(key);
    const dataSets = [
      STAFF_MEMBERS,
      YEARS_LAST_AVERAGE,
      YEARS_OF_EXPERENCE,
      TEACHER_AGE,
      GRAY_ZONE_TIME,
      GRAY_ZONE_STRESS_LEVEL
    ];

    return index >= 0 && index < dataSets.length ? mapLabel(value, dataSets[index]) : '';
  };

  const fetchStatistic = async (city_id: string) => {
    setIsLoading(true);
    try {
      const results = await getStatistic(city_id);
      const chartData = chartHelper.processData(results) as ChartData;
      setChartData(chartData);
      setSelectedComment(chartData.comments.length ? chartData.comments[0] : undefined);
      setSchools(
        chartData.comments.length ? chartData.comments.map((x, index) => ({ ...x, selected: index === 0 })) : []
      );
      setIsLoading(false);
    } catch (e) {
      toast.error('統計データの取得中にエラーが発生しました');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const listCities = await getCities();
        setCities(
          listCities.map((item) => {
            return {
              text: item.name,
              value: item.id.toString()
            };
          })
        );
      } catch (e) {
        toast.error('都市リストの取得中にエラーが発生しました');
      }
    };
    fetchData();
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateComlumn);
    return () => window.removeEventListener('resize', updateComlumn);
  }, []);

  const updateComlumn = () => {
    if (commentRef.current) {
      const rs = Math.min(
        Math.floor((commentRef.current.offsetWidth + commentItemSpace) / (commentItemSize + commentItemSpace)),
        12
      );
      setComlums(rs);
    }
  };

  useRefEffect(commentRef, (newValue: any) => {
    if (newValue) {
      updateComlumn();
    }
  });

  const handleSelectComment = (selectedIndex: number) => {
    setSchools(schools.map((item, index) => ({ ...item, selected: index === selectedIndex })));
    setSelectedComment(chartData?.comments[selectedIndex]);
  };

  const renderNodata = (size: 'large' | 'small' = 'large') => {
    return <div className={`text-center text-sm ${size === 'large' ? 'py-6' : 'py-2'}`}>データ無し</div>;
  };

  return (
    <div className='lg:m-10 sm:m-4 m-2'>
      <Title title='アンケート結果報告書' />
      <div className='grid grid-cols-1 lg:my-10 sm:my-5 my-2 lg:px-2'>
        <div className='lg:text-xl text-[18px] font-semibold lg:mb-4 mb-2'>調査を表示する都市を選択してください</div>
        <Select value={''} onChange={(option: OptionItem) => fetchStatistic(option.value)} options={cities} />
      </div>
      <LoadingWrapper isLoading={isLoading}>
        {chartData && (
          <div className='lg:my-10 sm:my-5 my-2 lg:px-2 w-full mx-auto flex flex-col lg:gap-8 sm:gap-6 gap-4'>
            {/* ENJOIJ */}
            <div className='ring-1 lg:py-4 py-2 ring-gray-medium bg-white'>
              <p className='lg:text-[28px] md:text-[24px] text-[20px] font-bold text-primary text-center lg:mb-8 mb-4'>
                項目別評価
              </p>
              <div className='grid lg:grid-cols-3 lg:gap-4 grid-cols-1 gap-y-1'>
                <div className='lg:col-span-2 col-span-1'>
                  <div className='text-center lg:text-xl text-[18px] font-semibold lg:mb-4 mb-2'>
                    {chartData.currentCity}市
                  </div>
                  <div className='grid lg:grid-cols-2 lg:gap-2 sm:grid-cols-2 sm:gap-2 gird-cols-1 gap-1'>
                    {chartHelper.enjojistyles.map((style, index) => (
                      <Chart
                        height={220}
                        id={style}
                        type='bar'
                        title={style}
                        key={index}
                        categories={chartHelper.monthAge}
                        series={chartData.enjoji.current_city[index]}
                        showXAsisLabel
                        colors={['#FF5733', '#2E93fA']}
                        xaxisTooltipFormatter={(val: any, opts: any) => {
                          return monthAge(opts.dataPointIndex + 1);
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className='col-span-1'>
                  <div className='text-center lg:text-xl text-[18px] font-semibold lg:mb-4 mb-2'>他自治体との比較</div>
                  <div className='grid lg:grid-cols-2 sm:grid-cols-2 sm:gap-2 grid-cols-1 gap-1'>
                    {chartHelper.enjojistyles.map((style, index) => (
                      <Chart
                        id={style}
                        height={Math.max(chartData.enjoji.all_cities[index].cities.length * 35, 150)}
                        type='bar'
                        key={index}
                        title={style}
                        stacked
                        horizontal
                        showXAsisLabel={false}
                        categories={chartData.enjoji.all_cities[index].cities}
                        series={chartData.enjoji.all_cities[index].data}
                        colors={['#FF5733']}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {/* AGE */}
            <div className='ring-1 lg:py-4 py-2 ring-gray-medium bg-white'>
              <p className='lg:text-[28px] md:text-[24px] text-[20px] font-bold text-primary text-center lg:mb-8 mb-4'>
                年齢別評価
              </p>
              <div className='grid lg:grid-cols-2 lg:gap-4 grid-cols-1 gap-y-1'>
                <div className='col-span-1'>
                  <div className='text-center lg:text-xl text-[18px] font-semibold lg:mb-4 mb-2'>
                    {chartData.currentCity}市{' '}
                  </div>
                  <div className='grid lg:grid-cols-3 lg:gap-2 sm:grid-cols-2 sm:gap-2 grid-cols-1 gap-1'>
                    {chartHelper.ages.map((age, index) => (
                      <Chart
                        height={230}
                        id={age}
                        key={index}
                        type='line'
                        title={age}
                        categories={chartHelper.enjojistyles}
                        series={chartData.ages.seriesCurrentCity[index]}
                        showXAsisLabel
                      />
                    ))}
                  </div>
                </div>
                <div className='col-span-1'>
                  <div className='text-center lg:text-xl text-[18px] font-semibold lg:mb-4 mb-2'>他自治体との比較</div>
                  <div className='grid lg:grid-cols-3 lg:gap-2 sm:grid-cols-2 sm:gap-2 grid-cols-1 gap-1'>
                    {chartHelper.ages.map((age, index) => (
                      <Chart
                        id={age}
                        height={Math.max(chartData.ages.seriesAllCities[index].cities.length * 35, 150)}
                        type='bar'
                        title={age}
                        key={index}
                        stacked
                        horizontal
                        showXAsisLabel={false}
                        categories={chartData.ages.seriesAllCities[index].cities}
                        series={chartData.ages.seriesAllCities[index].values}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {/* CHILD DEVELOPMENT */}
            <div className='ring-1 lg:py-4 py-2 ring-gray-medium bg-white'>
              <p className='lg:text-[28px] md:text-[24px] text-[20px] font-bold text-primary text-center lg:mb-8'>
                子どもの発達地域別評価
              </p>
              {chartData.childDevelopments.seriesAllItems.schools?.length ? (
                <div className='grid lg:grid-cols-3 lg:gap-4 grid-cols-1 gap-y-1'>
                  <div className='col-span-1 flex '>
                    <div className='w-full'>
                      <Chart
                        id='chart2'
                        height={Math.max(chartData.childDevelopments.seriesAllItems.schools.length * 30, 100)}
                        type='bar'
                        stacked
                        horizontal
                        showXAsisLabel={false}
                        categories={chartData.childDevelopments.seriesAllItems.schools}
                        series={chartData.childDevelopments.seriesAllItems.values}
                      />
                    </div>
                  </div>
                  <div className='lg:col-span-2 col-span-1'>
                    <div className='grid lg:grid-cols-3 sm:grid-cols-2 sm:gap-2 grid-cols-1 gap-1'>
                      {chartHelper.enjojistyles.map((style, index) => (
                        <Chart
                          id={style}
                          height={Math.max(chartData.childDevelopments.seriesPerItems[index].schools.length * 30, 100)}
                          type='bar'
                          title={style}
                          key={index}
                          horizontal
                          categories={chartData.childDevelopments.seriesPerItems[index].schools}
                          series={chartData.childDevelopments.seriesPerItems[index].values}
                          showXAsisLabel={false}
                          colors={[defaultColors[index]]}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                renderNodata()
              )}
            </div>
            {/* NURSERY SCHOOL */}
            <div className='ring-1 lg:py-4 py-2 ring-gray-medium bg-white'>
              <p className='lg:text-[28px] md:text-[24px] text-[20px] font-bold text-primary text-center lg:mb-8 mb-4'>
                保育園地域別評価
              </p>
              {chartData.nurserySchools.seriesAllItems?.schools?.length ? (
                <div className='grid lg:grid-cols-3 lg:gap-4 grid-cols-1 gap-y-1'>
                  <div className='col-span-1 flex'>
                    <div className='w-full'>
                      <Chart
                        id='chart2'
                        height={Math.max(chartData.nurserySchools.seriesAllItems.schools.length * 30, 100)}
                        type='bar'
                        stacked
                        horizontal
                        showXAsisLabel={false}
                        categories={chartData.nurserySchools.seriesAllItems.schools}
                        series={chartData.nurserySchools.seriesAllItems.values}
                        colors={defaultColors}
                        yaxisTooltipFormatter={(val, objChart) => formatterCustom(val, objChart, true)}
                      />
                    </div>
                  </div>
                  <div className='lg:col-span-2 col-span-1'>
                    <div className='grid lg:grid-cols-3 sm:grid-cols-2 sm:gap-2 grid-cols-1 gap-1'>
                      {chartHelper.schoolEvaluation.map((school, index) => (
                        <Chart
                          id={school}
                          height={Math.max(chartData.nurserySchools.seriesPerItems[index].schools.length * 30, 100)}
                          type='bar'
                          title={school}
                          key={index}
                          horizontal
                          categories={chartData.nurserySchools.seriesPerItems[index].schools}
                          series={chartData.nurserySchools.seriesPerItems[index].values}
                          showXAsisLabel={false}
                          colors={[defaultColors[index]]}
                          yaxisTooltipFormatter={(val) => formatterCustom(val, school, false)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                renderNodata()
              )}
            </div>
            {/* COMMENTS */}
            <div className='ring-1 py-4 ring-gray-medium bg-white  overflow-auto max-h-[600px]'>
              <p className='text-[28px] font-bold text-primary text-center mb-4'>保育士の声</p>
              {schools.length ? (
                <div className='flex flex-col p-4'>
                  <div ref={commentRef} className={`grid grid-cols-${columns}  gap-4`}>
                    {schools.map((school, index) => (
                      <div key={index} className='flex justify-center' onClick={() => handleSelectComment(index)}>
                        <div
                          className={`flex flex-col justify-center w-[140px] h-[140px] gap-2 rounded-lg p-4 transition ${
                            school.selected ? 'ring-2 ring-primary text-primary scale-105' : 'ring-1 ring-gray-200'
                          }  hover:ring-2 hover:ring-primary hover:text-primary `}
                        >
                          <div className='flex justify-center'>
                            <UserIcon className='h-10 w-10' />
                            <ChatBubbleLeftRightIcon className='w-8 h-8' />
                          </div>
                          <div className='text-center text-sm line-clamp-3'>{school.name}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className='text-sm pt-6 text-primary md:mx-4'>
                    園の名前をクリックすると、その園の保育士からのコメントが閲覧できます
                  </div>
                  <div className='text-[24px] font-bold text-primary my-8 md:mx-4'>コメント一覧</div>
                  <div className='flex flex-col gap-4 md:px-4 transition'>
                    {selectedComment?.comments?.length
                      ? selectedComment?.comments?.map((item, index) => (
                          <div key={index} className=' border border-gray-100 rounded-xl py-2 px-4'>
                            {item}
                          </div>
                        ))
                      : renderNodata('small')}
                  </div>
                </div>
              ) : (
                renderNodata()
              )}
            </div>

            <div className='ring-1 p-4 ring-gray-medium bg-white'>
              <p className='text-[28px] font-bold text-primary text-center mb-4'>総合評価</p>
              {chartData.comprehensiveEvaluation.comprehensiveChart?.schools?.length ? (
                <div className='grid lg:grid-cols-4 lg:gap-4 md:grid-cols-2 md:gap-4 grid-cols-1 gap-y-4'>
                  <div className='flex flex-col items-center gap-3'>
                    子ども発達リスク
                    <MapComponent data={chartData.comprehensiveEvaluation.childDevelopments} />
                  </div>
                  <div className='flex flex-col items-center gap-3'>
                    保育園リスク
                    <MapComponent data={chartData.comprehensiveEvaluation.nurserySchool} />
                  </div>
                  <div className='flex flex-col items-center gap-3'>
                    総合評価
                    <MapComponent data={chartData.comprehensiveEvaluation.comprehensive} />
                  </div>
                  <div className='w-full'>
                    <Chart
                      id={'conprehensive'}
                      height={Math.max(chartData.comprehensiveEvaluation.comprehensiveChart.schools.length * 30, 100)}
                      type='bar'
                      stacked
                      horizontal
                      showXAsisLabel={false}
                      categories={chartData.comprehensiveEvaluation.comprehensiveChart.schools}
                      series={chartData.comprehensiveEvaluation.comprehensiveChart.values}
                    />
                  </div>
                </div>
              ) : (
                renderNodata()
              )}
            </div>
          </div>
        )}
      </LoadingWrapper>
    </div>
  );
};

export default SurveyReportPage;
