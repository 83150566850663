import { RadioGroupField } from '../../FormField/RadioGroupField';
import { OptionItem } from '../../../../types/components/common/select';
import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { RespondentFormData } from '../../../../types/asq';
import React, { ComponentProps, useMemo } from 'react';
import { FlexColumnItemContainer } from '../FlexColumnItemContainer';
import { TextAreaInputField } from '../../FormField/TextAreaInputField';
import { QuestionContainer } from '../QuestionContainer';

interface Props extends Omit<ComponentProps<typeof FlexColumnItemContainer>, 'children'> {
  choiceForDetail: number;
  questionText: string;
  note?: string;
  questionIndex: number;
  options: OptionItem[];
  setValue: UseFormSetValue<RespondentFormData>;
  nestedFieldKey: string;
  radioValue?: number;
  textValue?: string;
  required?: boolean;
  formErrors: FieldErrors<RespondentFormData>;
}

export const OverallEvaluationQuestionContainer = ({
  questionText,
  questionIndex,
  note,
  required,
  setValue,
  options,
  radioValue,
  textValue,
  nestedFieldKey,
  formErrors,
  choiceForDetail,
  ...rest
}: Props) => {
  const currentRadioValue = useMemo(() => {
    return radioValue === null || radioValue === undefined ? '' : radioValue.toString();
  }, [radioValue]);

  const needDetail = useMemo(() => {
    return choiceForDetail === radioValue;
  }, [choiceForDetail, radioValue]);

  const detailQuestionText = useMemo(() => {
    const opt = options.find((option) => option.value === choiceForDetail.toString());
    const label = opt?.text;
    return `「${label}」を選んだ場合、下記に詳しく説明してください。`;
  }, [choiceForDetail, options]);

  return (
    <FlexColumnItemContainer {...rest}>
      <div className='flex items-center gap-2 mb-4'>
        <span className='p-2.5 bg-gray-light text-sm sm:text-base font-semibold rounded-lg ring-2 ring-gray-medium'>
          Q{questionIndex}
        </span>
        <span className={`font-semibold sm:text-xl text-lg`}>{questionText}</span>
        {required && <span className='text-red text-sm'>*</span>}
      </div>
      {note && <p className='text-sm text-gray-500'>{note}</p>}
      <RadioGroupField
        options={options}
        setValue={setValue}
        value={currentRadioValue}
        fieldKey={`overall_evaluation_response_group`}
        nestedFieldKey={`${nestedFieldKey}.choice`}
        formErrors={formErrors}
      />
      {needDetail && (
        <QuestionContainer questionText={detailQuestionText} required={true}>
          <TextAreaInputField
            setValue={setValue}
            value={textValue}
            formErrors={formErrors}
            fieldKey={'overall_evaluation_response_group'}
            nestedFieldKey={`${nestedFieldKey}.detail`}
          />
        </QuestionContainer>
      )}
    </FlexColumnItemContainer>
  );
};
