import RadioGroup from '../../Common/RadioGroup';
import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { RespondentFormData } from '../../../types/asq';
import { OptionItem } from '../../../types/components/common/select';
import { useMemo } from 'react';

interface Props {
  setValue: UseFormSetValue<RespondentFormData>;
  value: string; // 'male' といった文字列も可能性があるので string にしている
  formErrors: FieldErrors<RespondentFormData>;
  fieldKey: keyof RespondentFormData;
  /**
   * nestedFieldKey is required when fieldKey is 'daily_activity_response_group'
   * e.g. 'sections.0.responses.0.choice'
   */
  nestedFieldKey?: string;
  options: OptionItem[];
}

function validateDailyActivityKey(nestedFieldKey?: string): void {
  if (!nestedFieldKey) throw new Error('nestedFieldKey is required');
  const regex = /^sections\.\d+\.responses\.\d+\.choice$/;
  if (!regex.test(nestedFieldKey)) throw new Error(`Invalid nestedFieldKey: ${nestedFieldKey}`);
}

function validateOverallEvaluationKey(nestedFieldKey?: string): void {
  if (!nestedFieldKey) throw new Error('nestedFieldKey is required');
  const regex = /^responses\.\d+\.choice$/;
  if (!regex.test(nestedFieldKey)) throw new Error(`Invalid nestedFieldKey: ${nestedFieldKey}`);
}

export const RadioGroupField = ({ setValue, value, formErrors, fieldKey, nestedFieldKey, options }: Props) => {
  const setDailyActivityValue = (newValue: string) => {
    validateDailyActivityKey(nestedFieldKey);
    const indexes = nestedFieldKey!.split('.').map((v) => Number(v));
    const sectionIndex = indexes[1];
    const responseIndex = indexes[3];
    setValue(
      `daily_activity_response_group.sections.${sectionIndex}.responses.${responseIndex}.choice`,
      Number(newValue)
    );
  };

  const setOverallEvaluationValue = (newValue: string) => {
    validateOverallEvaluationKey(nestedFieldKey);
    const indexes = nestedFieldKey!.split('.').map((v) => Number(v));
    const questionIndex = indexes[1];
    setValue(`overall_evaluation_response_group.responses.${questionIndex}.choice`, Number(newValue));
  };

  const handleOnChange = (newValue: string) => {
    if (fieldKey === 'daily_activity_response_group') {
      setDailyActivityValue(newValue);
      return;
    }
    if (fieldKey === 'overall_evaluation_response_group') {
      setOverallEvaluationValue(newValue);
      return;
    }
    setValue(fieldKey, newValue);
  };

  const errorMessage = useMemo(() => {
    if (fieldKey === 'daily_activity_response_group') {
      validateDailyActivityKey(nestedFieldKey);
      const indexes = nestedFieldKey!.split('.').map((v) => Number(v));
      const sectionIndex = indexes[1];
      const responseIndex = indexes[3];
      return formErrors.daily_activity_response_group?.sections?.[sectionIndex]?.responses?.[responseIndex]?.choice
        ?.message;
    }
    if (fieldKey === 'overall_evaluation_response_group') {
      validateOverallEvaluationKey(nestedFieldKey);
      const indexes = nestedFieldKey!.split('.').map((v) => Number(v));
      const questionIndex = indexes[1];
      return formErrors.overall_evaluation_response_group?.responses?.[questionIndex]?.choice?.message;
    }
    return formErrors[fieldKey]?.message;
  }, [formErrors, fieldKey, nestedFieldKey]);
  return <RadioGroup value={value} onChange={handleOnChange} options={options} errorMessage={errorMessage} />;
};
