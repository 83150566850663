import Button from 'components/Button';
import RadioGroup from 'components/Common/RadioGroup';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnswerGroupItem, QuestionGroup, QuestionGroupItem } from 'types';
import { useEffect, useState } from 'react';
import { getQuestionGroups, saveAnswers } from 'helpers/backend-helper';
import LoadingWrapper from 'components/Loading/LoadingWrapper';
import Title from 'components/Title';
import { toast } from 'react-toastify';

const QuestionGroupPage = () => {
  const [questionGroups, setQuestionGroups] = useState<QuestionGroup[]>([]);
  const location = useLocation();
  const { state } = location;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabledSubmit, setIsDisabledSumbit] = useState<boolean>(true);
  const [submitData, setSubmitData] = useState<{ [key: number]: string }>({});
  const navigate = useNavigate();

  const fetchQuestionGroups = async (type: string) => {
    setIsLoading(true);
    try {
      const res = await getQuestionGroups(type);
      setQuestionGroups(res);
      setIsLoading(false);
    } catch (e) {
      toast.error('アンケートパッケージのリストの取得中にエラーが発生しました');
    }
  };

  useEffect(() => {
    if (state && state.type) {
      fetchQuestionGroups(state.type);
    }
  }, []);

  const handleChange = (questionId: number, answerId: string) => {
    setIsDisabledSumbit(false);
    setSubmitData({
      ...submitData,
      [questionId]: answerId
    });
  };

  const handleSubmit = async () => {
    const postData = Object.keys(submitData).map((questionId) => {
      return {
        question_id: +questionId,
        answer_id: +submitData[+questionId]
      };
    });
    try {
      await saveAnswers(postData);
      navigate('/submit-success', { state: { title: '' } });
    } catch (e) {
      toast('エラーが発生しました。もう一度お試しください。');
    }
  };

  const renderQuestionItem = (question: QuestionGroupItem, index: number, answers: AnswerGroupItem[]) => {
    const listAnswers = answers.map((item) => {
      return {
        text: item.text,
        value: item.id + ''
      };
    });

    return (
      <div key={index} className={`sm:p-8 p-4 rounded-lg bg-white`}>
        <div>
          <div className='flex flex-row items-center gap-2 mb-4'>
            <span className='p-2.5 bg-gray-light text-sm sm:text-base font-semibold rounded-lg ring-2 ring-gray-medium'>
              Q{index}
            </span>
            <span className={`font-semibold sm:text-xl text-lg`}>{question.text}</span>
          </div>
        </div>
        <RadioGroup
          value={submitData[question.id] ?? ''}
          onChange={(answerId: string) => handleChange(question.id, answerId)}
          options={listAnswers}
        />
      </div>
    );
  };

  return (
    <div className='container mx-auto py-10 flex flex-col items-center'>
      <Title title={state?.label || ''} />
      <LoadingWrapper contentClassName='w-full' isLoading={isLoading} isEmpty={!questionGroups.length} emptyText=''>
        {questionGroups.map((item) => (
          <div key={item.id} className='my-10 md:px-10 px-4 w-full mx-auto '>
            <h3 className='sm:text-2xl text-xl mb-10 font-bold'>{item.text}</h3>
            <div className='flex flex-col gap-6 sm:gap-10 mb-10 w-full'>
              {item.questions.map((question, index) => renderQuestionItem(question, index + 1, item.answers))}
            </div>
          </div>
        ))}
        <div className='w-full flex justify-center'>
          <Button title='送信' disabled={isDisabledSubmit} onClick={handleSubmit} />
        </div>
      </LoadingWrapper>
    </div>
  );
};

export default QuestionGroupPage;
