import React from 'react';

export const FlexColumnItemContainer = ({
  children,
  ...rest
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <div className='rounded-lg bg-white sm:p-8 p-4' {...rest}>
      {children}
    </div>
  );
};
