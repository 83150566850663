import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { DailyActivity, RespondentFormData } from '../../../../types/asq';
import { ComponentProps, useMemo } from 'react';
import { FlexColumnItemContainer } from '../FlexColumnItemContainer';
import { DailyActivitySectionContainer } from './DailyActivitySectionContainer';

interface Props extends ComponentProps<typeof FlexColumnItemContainer> {
  dailyActivity: DailyActivity;
  setValue: UseFormSetValue<RespondentFormData>;
  currentFormData: RespondentFormData;
  formErrors: FieldErrors<RespondentFormData>;
}

export const DailyActivityContainer = ({ dailyActivity, currentFormData, setValue, formErrors, ...rest }: Props) => {
  const dailyActivityQuestionOptions = useMemo(() => {
    if (!dailyActivity) return [];
    return dailyActivity.choice_group.choices.map((choice) => {
      return {
        text: choice.text,
        value: choice.id.toString() // choice model instance fk
      };
    });
  }, [dailyActivity]);

  if (!dailyActivity) return null;

  return (
    <FlexColumnItemContainer className={'flex flex-col gap-8'} {...rest}>
      <FlexColumnItemContainer className={'rounded-lg bg-white sm:p-8 p-4 flex flex-col gap-8'}>
        <p className='font-semibold'>* お子さんの日常の行動についてうかがいます。</p>
        <p className='font-semibold'>{dailyActivity.question_text}</p>
        {dailyActivity.note && <p className='text-sm text-gray-500'>{dailyActivity.note}</p>}
      </FlexColumnItemContainer>
      {dailyActivity.sections.map((section, sectionIndex) => {
        return (
          <DailyActivitySectionContainer
            key={`section-${section.id}`}
            section={section}
            sectionIndex={sectionIndex}
            options={dailyActivityQuestionOptions}
            setValue={setValue}
            currentFormData={currentFormData}
            formErrors={formErrors}
            className={'rounded-lg bg-white sm:p-8 p-4 flex flex-col gap-8'}
          />
        );
      })}
    </FlexColumnItemContainer>
  );
};
