import { FC } from 'react';

interface ITitleProps {
  title: string;
  description?: string;
  textAlign?: string;
}

const Title: FC<ITitleProps> = ({ title, description, textAlign = 'text-center' }) => {
  return (
    <div className={textAlign}>
      <h1 className='sm:text-3xl text-2xl mb-3 font-bold'>{title}</h1>
      {description && <p className='text-[12px] sm:text-[13px] text-gray-dark'>{description}</p>}
    </div>
  );
};

export default Title;
