import { Question } from 'types';

export const getGeneralQuestions = (): Question[] => {
  return [
    {
      title: '園職員の数は何人程度ですか',
      answer_field_type: 'radio',
      name: 'staff_members',
      options: [
        { text: '~10', value: '1' },
        { text: '10-20', value: '2' },
        { text: '20-30', value: '3' },
        { text: '30~', value: '4' }
      ]
    },
    {
      title: '継続年数は平均何年程度ですか',
      answer_field_type: 'radio',
      name: 'years_last_average',
      options: [
        { text: '1年以内', value: '1' },
        { text: '1~2年', value: '2' },
        { text: '2~5年', value: '3' },
        { text: '5~10年', value: '4' }
      ]
    },
    {
      title: '保育士としての経験年数',
      answer_field_type: 'radio',
      name: 'year_of_experiences',
      options: [
        { text: '1年以内', value: '1' },
        { text: '1~2年', value: '2' },
        { text: '2~5年', value: '3' },
        { text: '5~10年', value: '4' }
      ]
    },
    {
      title: '年齢',
      answer_field_type: 'radio',
      name: 'teacher_age',
      options: [
        { text: '20代', value: '1' },
        { text: '30代', value: '2' },
        { text: '40代', value: '3' },
        { text: '50代', value: '4' },
        { text: '60代', value: '5' }
      ]
    },
    {
      title: 'グレーゾーンの子どもへの対応に必要な時間',
      answer_field_type: 'radio',
      name: 'gray_zone_time',
      options: [
        { text: '~30分/日', value: '1' },
        { text: '30-60分/日', value: '2' },
        { text: '1-2時間/日', value: '3' },
        { text: '2-3時間/日', value: '4' }
      ]
    },
    {
      title: '発達が気になる子に対して、どのようなお悩みを感じていますか？',
      answer_field_type: 'radio',
      name: 'gray_zone_stress_level',
      options: [
        { text: '接し方が分からない', value: '1' },
        { text: '発達障害や接し方に関する必要な情報が見つからない', value: '2' },
        { text: '理解を深めたいが、時間がない', value: '3' },
        { text: 'その子の対応に時間がかかり、クラス運営が難しい', value: '4' },
        { text: '特に悩みは感じていない・対処できている', value: '5' },
        { text: 'その他（自由記述）', value: '6' }
      ]
    },
    {
      title: '発達が気になる子どもへの対応に関して、困りごとがあれば自由に記載してください',
      answer_field_type: 'textarea',
      name: 'gray_zone_comment',
      inputProps: { placeholder: '自由記述' }
    }
  ];
};

export const getSurveyEnjojiQuestions = (surveyEnjojiIndex: number): Question[] => {
  return [
    {
      title: '子どものID',
      answer_field_type: 'input',
      name: `survey_enjojies.${surveyEnjojiIndex}.child`,
      required: true
    },
    {
      title: '子どもの月齢',
      answer_field_type: 'select',
      name: `survey_enjojies.${surveyEnjojiIndex}.child_age`,
      options: [
        { text: '0歳1ヶ月', value: '1' },
        { text: '0歳2ヶ月', value: '2' },
        { text: '0歳3ヶ月', value: '3' },
        { text: '0歳4ヶ月', value: '4' },
        { text: '0歳5ヶ月', value: '5' },
        { text: '0歳6ヶ月', value: '6' },
        { text: '0歳7ヶ月', value: '7' },
        { text: '0歳8ヶ月', value: '8' },
        { text: '0歳9ヶ月', value: '9' },
        { text: '0歳10ヶ月', value: '10' },
        { text: '0歳11ヶ月', value: '11' },
        { text: '1歳0ヶ月', value: '12' },
        { text: '1歳1ヶ月', value: '13' },
        { text: '1歳2ヶ月', value: '14' },
        { text: '1歳3ヶ月', value: '15' },
        { text: '1歳4ヶ月', value: '16' },
        { text: '1歳5ヶ月', value: '17' },
        { text: '1歳6ヶ月', value: '18' },
        { text: '1歳7ヶ月', value: '19' },
        { text: '1歳8ヶ月', value: '20' },
        { text: '1歳9ヶ月', value: '21' },
        { text: '1歳10ヶ月', value: '22' },
        { text: '1歳11ヶ月', value: '23' },
        { text: '2歳0ヶ月', value: '24' },
        { text: '2歳1ヶ月', value: '25' },
        { text: '2歳2ヶ月', value: '26' },
        { text: '2歳3ヶ月', value: '27' },
        { text: '2歳4ヶ月', value: '28' },
        { text: '2歳5ヶ月', value: '29' },
        { text: '2歳6ヶ月', value: '30' },
        { text: '2歳7ヶ月', value: '31' },
        { text: '2歳8ヶ月', value: '32' },
        { text: '2歳9ヶ月', value: '33' },
        { text: '2歳10ヶ月', value: '34' },
        { text: '2歳11ヶ月', value: '35' },
        { text: '3歳0ヶ月', value: '36' },
        { text: '3歳1ヶ月', value: '37' },
        { text: '3歳2ヶ月', value: '38' },
        { text: '3歳3ヶ月', value: '39' },
        { text: '3歳4ヶ月', value: '40' },
        { text: '3歳5ヶ月', value: '41' },
        { text: '3歳6ヶ月', value: '42' },
        { text: '3歳7ヶ月', value: '43' },
        { text: '3歳8ヶ月', value: '44' },
        { text: '3歳9ヶ月', value: '45' },
        { text: '3歳10ヶ月', value: '46' },
        { text: '3歳11ヶ月', value: '47' },
        { text: '4歳0ヶ月', value: '48' },
        { text: '4歳1ヶ月', value: '49' },
        { text: '4歳2ヶ月', value: '50' },
        { text: '4歳3ヶ月', value: '51' },
        { text: '4歳4ヶ月', value: '52' },
        { text: '4歳5ヶ月', value: '53' },
        { text: '4歳6ヶ月', value: '54' },
        { text: '4歳7ヶ月', value: '55' },
        { text: '4歳8ヶ月', value: '56' },
        { text: '4歳9ヶ月', value: '57' },
        { text: '4歳10ヶ月', value: '58' },
        { text: '4歳11ヶ月', value: '59' },
        { text: '5歳0ヶ月', value: '60' },
        { text: '5歳1ヶ月', value: '61' },
        { text: '5歳2ヶ月', value: '62' },
        { text: '5歳3ヶ月', value: '63' },
        { text: '5歳4ヶ月', value: '64' },
        { text: '5歳5ヶ月', value: '65' },
        { text: '5歳6ヶ月', value: '66' },
        { text: '5歳7ヶ月', value: '67' },
        { text: '5歳8ヶ月', value: '68' },
        { text: '5歳9ヶ月', value: '69' },
        { text: '5歳10ヶ月', value: '70' },
        { text: '5歳11ヶ月', value: '71' },
        { text: '6歳0ヶ月', value: '72' }
      ],
      required: true
    },
    {
      title: '遠城寺式発達検査',
      survey_enjojies: [
        {
          title: '子どもの粗大運動についてどこまで出来てるかを教えてください',
          answer_field_type: 'select',
          name: `survey_enjojies.${surveyEnjojiIndex}.enjoji1`,
          options: [
            { text: '0歳1ヶ月　あおむけでときどき左右に首の向きをかえる', value: '1' },
            { text: '0歳2ヶ月　腹ばいで頭をちょっとあげる', value: '2' },
            { text: '0歳3ヶ月　あおむけにして体をおこしたとき頭を保つ', value: '3' },
            { text: '0歳4ヶ月　首がすわる', value: '4' },
            { text: '0歳5ヶ月　横向きに寝かせると寝返りをする', value: '5' },
            { text: '0歳6ヶ月　寝返りをする', value: '6' },
            { text: '0歳7ヶ月　腹ばいで体をまわす', value: '7' },
            { text: '0歳8ヶ月　ひとりで座って遊ぶ', value: '8' },
            { text: '0歳9ヶ月　ものにつかまって立っている', value: '9' },
            { text: '0歳10ヶ月　つかまって立ち上がる', value: '10' },
            { text: '0歳11ヶ月　つたい歩きをする', value: '11' },
            { text: '1歳0ヶ月　座った位置から立ち上がる', value: '12' },
            { text: '1歳2ヶ月　2-3歩歩く', value: '14' },
            { text: '1歳4ヶ月　靴をはいて歩く', value: '16' },
            { text: '1歳6ヶ月　走る', value: '18' },
            { text: '1歳9ヶ月　ひとりで一段ごとに足をそろえながら階段をあがる', value: '21' },
            { text: '2歳0ヶ月　ボールを前にける', value: '24' },
            { text: '2歳3ヶ月　両足でぴょんぴょん飛ぶ', value: '27' },
            { text: '2歳6ヶ月　足を交互に出して階段をあがる', value: '30' },
            { text: '2歳9ヶ月　立ったままくるっとまわる', value: '33' },
            { text: '3歳0ヶ月　片足で2-3秒立つ', value: '36' },
            { text: '3歳4ヶ月　でんぐりがえしをする', value: '40' },
            { text: '3歳8ヶ月　幅とび（両足をそろえて前にとぶ）', value: '44' },
            { text: '4歳0ヶ月　片足で数歩とぶ', value: '48' },
            { text: '4歳4ヶ月　ブランコに立ち乗りしてこぐ', value: '52' },
            { text: '4歳8ヶ月　スキップができる', value: '56' }
          ],
          required: true
        },
        {
          title: '子どもの手の運動についてどこまで出来てるかを教えてください',
          answer_field_type: 'select',
          name: `survey_enjojies.${surveyEnjojiIndex}.enjoji2`,
          options: [
            { text: '0歳1ヶ月　手にふれたものをつかむ', value: '1' },
            { text: '0歳2ヶ月　手を口に持っていってしゃぶる', value: '2' },
            { text: '0歳3ヶ月　親にふれたものを取ろうとして手を動かす', value: '3' },
            { text: '0歳4ヶ月　おもちゃをつかんでいる', value: '4' },
            { text: '0歳5ヶ月　ガラガラを振る', value: '5' },
            { text: '0歳6ヶ月　手を出してものをつかむ', value: '6' },
            { text: '0歳7ヶ月　おもちゃを一方の手から他方に持ちかえる', value: '7' },
            { text: '0歳8ヶ月　親指と人さし指でつかもうとする', value: '8' },
            { text: '0歳9ヶ月　おもちゃのたいこをたたく', value: '9' },
            { text: '0歳10ヶ月　びんのふたを、あけたりしめたりする', value: '10' },
            { text: '0歳11ヶ月　おもちゃの車を手で走らせる', value: '11' },
            { text: '1歳0ヶ月　なぐり書きをする', value: '12' },
            { text: '1歳2ヶ月　コップの中の小粒をとり出そうとする', value: '14' },
            { text: '1歳4ヶ月　積木を二つ重ねる', value: '16' },
            { text: '1歳6ヶ月　コップからコップへ水をうつす', value: '18' },
            { text: '1歳9ヶ月　鉛筆でぐるぐるまるをかく', value: '21' },
            { text: '2歳0ヶ月　積木を横に二つ以上ならべる', value: '24' },
            { text: '2歳3ヶ月　鉄棒などに両手でぶらさがる', value: '27' },
            { text: '2歳6ヶ月　まねて直線を引く', value: '30' },
            { text: '2歳9ヶ月　まねて○をかく', value: '33' },
            { text: '3歳0ヶ月　はさみを使って紙を切る', value: '36' },
            { text: '3歳4ヶ月　ボタンをはめる', value: '40' },
            { text: '3歳8ヶ月　十字をかく', value: '44' },
            { text: '4歳0ヶ月　紙を直線にそって切る', value: '48' },
            { text: '4歳4ヶ月　はずむボールをつかむ', value: '52' },
            { text: '4歳8ヶ月　紙飛行機を自分で折る', value: '56' }
          ],
          required: true
        },
        {
          title: '子どもの基本的習慣についてどこまで出来てるかを教えてください',
          answer_field_type: 'select',
          name: `survey_enjojies.${surveyEnjojiIndex}.enjoji3`,
          options: [
            { text: '0歳1ヶ月　空腹時に抱くと顔を乳の方に向けてほしがる', value: '1' },
            { text: '0歳2ヶ月　満腹になると乳首を舌でおし出したり顔をそむけたりする', value: '2' },
            { text: '0歳3ヶ月　顔に布をかけられて不快を示す', value: '3' },
            { text: '0歳4ヶ月　さじから飲むことができる', value: '4' },
            { text: '0歳5ヶ月　おもちゃを見ると動きが活発になる', value: '5' },
            { text: '0歳6ヶ月　ビスケットなどを自分で食べる', value: '6' },
            { text: '0歳7ヶ月　コップから飲む', value: '7' },
            { text: '0歳8ヶ月　顔をふこうとするといやがる', value: '8' },
            { text: '0歳9ヶ月　コップなどを両手で口に持っていく', value: '9' },
            { text: '0歳10ヶ月　泣かずに欲求を示す', value: '10' },
            { text: '0歳11ヶ月　コップを自分で持って飲む', value: '11' },
            { text: '1歳0ヶ月　さじで食べようとする', value: '12' },
            { text: '1歳2ヶ月　お菓子のつつみ紙をとって食べる', value: '14' },
            { text: '1歳4ヶ月　自分の口元をひとりでふこうとする', value: '16' },
            { text: '1歳6ヶ月　パンツをはかせると両足をひろげる', value: '18' },
            { text: '1歳9ヶ月　ストローで飲む', value: '21' },
            { text: '2歳0ヶ月　排尿を予告する', value: '24' },
            { text: '2歳3ヶ月　ひとりでパンツを脱ぐ', value: '27' },
            { text: '2歳6ヶ月　こぼさないでひとりで食べる', value: '30' },
            { text: '2歳9ヶ月　靴をひとりではく', value: '33' },
            { text: '3歳0ヶ月　上着を自分で脱ぐ', value: '36' },
            { text: '3歳4ヶ月　顔をひとりで洗う', value: '40' },
            { text: '3歳8ヶ月　鼻をかむ', value: '44' },
            { text: '4歳0ヶ月　入浴後、ある程度自分で体を洗う', value: '48' },
            { text: '4歳4ヶ月　信号を見て正しく道路をわたる', value: '52' },
            { text: '4歳8ヶ月　ひとりで着衣ができる', value: '56' }
          ],
          required: true
        },
        {
          title: '子どもの対人関係についてどこまで出来てるかを教えてください',
          answer_field_type: 'select',
          name: `survey_enjojies.${surveyEnjojiIndex}.enjoji4`,
          options: [
            { text: '0歳1ヶ月　泣いているとき抱きあげるとしずまる', value: '1' },
            { text: '0歳2ヶ月　人の顔をじいっと見つめる', value: '2' },
            { text: '0歳3ヶ月　人の声がする方に向く', value: '3' },
            { text: '0歳4ヶ月　あやされると声を出して笑う', value: '4' },
            { text: '0歳5ヶ月　人を見ると笑いかける', value: '5' },
            { text: '0歳6ヶ月　鏡に映った自分の顔に反応する', value: '6' },
            { text: '0歳7ヶ月　親しみと怒った顔がわかる', value: '7' },
            { text: '0歳8ヶ月　鏡を見て笑いかけたり話しかけたりする', value: '8' },
            { text: '0歳9ヶ月　おもちゃをとられると不快を示す', value: '9' },
            { text: '0歳10ヶ月　身振りをまねする（オツムテンテンなど）', value: '10' },
            { text: '0歳11ヶ月　人見知りをする', value: '11' },
            { text: '1歳0ヶ月　父や母の後追いをする', value: '12' },
            { text: '1歳2ヶ月　ほめられると同じ動作を繰り返す', value: '14' },
            { text: '1歳4ヶ月　簡単な手伝いをする', value: '16' },
            { text: '1歳6ヶ月　困難なことに出会うと助けを求める', value: '18' },
            { text: '1歳9ヶ月　友達と手をつなぐ', value: '21' },
            { text: '2歳0ヶ月　親から離れて遊ぶ', value: '24' },
            { text: '2歳3ヶ月　電話ごっこをする', value: '27' },
            { text: '2歳6ヶ月　友達とけんかをすると言いつけにくる', value: '30' },
            { text: '2歳9ヶ月　年下の子どもの世話をやきたがる', value: '33' },
            { text: '3歳0ヶ月　ままごとで役を演じることができる', value: '36' },
            { text: '3歳4ヶ月　「こうしていい︖」と許可を求める', value: '40' },
            { text: '3歳8ヶ月　友達と順番にもとを使う（ブランコなど）', value: '44' },
            { text: '4歳0ヶ月　母親にことわって友達の家に遊びに行く', value: '48' },
            { text: '4歳4ヶ月　じゃんけんで勝負を決める', value: '52' },
            { text: '4歳8ヶ月　砂場で二人以上で協力して一つの山をつくる', value: '56' }
          ],
          required: true
        },
        {
          title: '子どもの発語についてどこまで出来てるかを教えてください',
          answer_field_type: 'select',
          name: `survey_enjojies.${surveyEnjojiIndex}.enjoji5`,
          options: [
            { text: '0歳1ヶ月　元気な声で泣く', value: '1' },
            { text: '0歳2ヶ月　いろいろな泣き声を出す', value: '2' },
            { text: '0歳3ヶ月　泣かずに声を出す（アー、ウァ、など）', value: '3' },
            { text: '0歳4ヶ月　声を出して笑う', value: '4' },
            { text: '0歳5ヶ月　キャーキャー言う', value: '5' },
            { text: '0歳6ヶ月　人に向かって声を出す', value: '6' },
            { text: '0歳7ヶ月　おもちゃなどに向かって声を出す', value: '7' },
            { text: '0歳8ヶ月　マ、バ、パなどの音声が出る', value: '8' },
            { text: '0歳9ヶ月　タ、ダ、チャなどの音声が出る', value: '9' },
            { text: '0歳10ヶ月　さかんにおしゃべりをする（補語）', value: '10' },
            { text: '0歳11ヶ月　音声をまねようとする', value: '11' },
            { text: '1歳0ヶ月　ことばを1-2語、正しくまねる', value: '12' },
            { text: '1歳2ヶ月　2語言える', value: '14' },
            { text: '1歳4ヶ月　3語言える', value: '16' },
            { text: '1歳6ヶ月　絵本を見て一つのものの名前を言う', value: '18' },
            { text: '1歳9ヶ月　絵本を見て三つのものの名前を言う', value: '21' },
            { text: '2歳0ヶ月　二語文を話す（「わんわんきた」など）', value: '24' },
            { text: '2歳3ヶ月　「きれいね」「おいしいね」などの表現ができる', value: '27' },
            { text: '2歳6ヶ月　自分の姓名を言う', value: '30' },
            { text: '2歳9ヶ月　二数詞の復唱（5-8, 6-2）', value: '33' },
            { text: '3歳0ヶ月　二語文の復唱（小さな人形、赤いふうせん、おいしいお菓子）', value: '36' },
            { text: '3歳4ヶ月　同年齢の子どもと会話ができる', value: '40' },
            { text: '3歳8ヶ月　文章の復唱（きれいな花がさいています。）', value: '44' },
            { text: '4歳0ヶ月　両親の姓名、住所を言う', value: '48' },
            { text: '4歳4ヶ月　四数詞の復唱（5-2-4-9）', value: '52' },
            {
              text: '4歳8ヶ月　文書の復唱（子どもが二人ブランコに乗っています。山の上に大きな月が出ました。きのうお母さんと買い物に行きました。）',
              value: '56'
            }
          ],
          required: true
        },
        {
          title: '子どもの言語理解についてどこまで出来てるかを教えてください',
          answer_field_type: 'select',
          name: `survey_enjojies.${surveyEnjojiIndex}.enjoji6`,
          options: [
            { text: '0歳1ヶ月:大きな音に反応する', value: '1' },
            { text: '0歳3ヶ月:人の声でしずまる', value: '3' },
            { text: '0歳5ヶ月　母の声と他の人の声をききわける', value: '5' },
            { text: '0歳7ヶ月　親の話し方で感情をききわける（禁止など）', value: '7' },
            { text: '0歳10ヶ月　「いけません」と言うと、ちょっと手をひっこめる', value: '10' },
            { text: '0歳11ヶ月　「バイバイ」や「さようなら」のことばに反応する', value: '11' },
            { text: '1歳0ヶ月　要求を理解する（おいで、ちょうだい、ねんねのうち少なくとも一つ）', value: '12' },
            { text: '1歳2ヶ月　要求を理解する（おいで、ちょうだい、ねんねのうち全て）', value: '14' },
            { text: '1歳4ヶ月　簡単な命令を実行する（「新聞を持っていらっしゃい」など）', value: '16' },
            { text: '1歳6ヶ月　絵本を読んでもらいたがる', value: '18' },
            { text: '1歳9ヶ月　目、口、耳、手、足、腹を指示する', value: '21' },
            { text: '2歳0ヶ月　「もうひとつ」「もうすこし」がわかる', value: '24' },
            { text: '2歳3ヶ月　鼻、髪、⻭、舌、へそ、爪を指示する', value: '27' },
            { text: '2歳6ヶ月　大きい、小さいがわかる', value: '30' },
            { text: '2歳9ヶ月　⻑い、短いがわかる', value: '33' },
            { text: '3歳0ヶ月　赤、⻘、⻩、緑がわかる', value: '36' },
            { text: '3歳4ヶ月　高い、低いがわかる', value: '40' },
            { text: '3歳8ヶ月　数の概念がわかる（3まで）', value: '44' },
            { text: '4歳0ヶ月　用途による物の指（本、鉛筆、時計、いす、電話）', value: '48' },
            { text: '4歳4ヶ月　数の概念がわかる（5まで）', value: '52' },
            { text: '4歳8ヶ月　左右がわかる', value: '56' }
          ],
          required: true
        }
      ]
    }
  ];
};
