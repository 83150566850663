/* eslint-disable no-unused-vars */
import { ChartData, ChartResult } from 'types';

export const ages = ['0歳', '1歳', '2歳', '3歳', '4歳'];
export const enjojistyles = ['移動運動', '手の運動', '基本的習慣', '対人関係', '発語', '言語理解'];
export const schoolEvaluation = ['園規模', '離職率', '保育士経験年数', '保育士年齢', '対応所要時間', 'ストレス度'];
export const monthAge = Array.from({ length: 72 }, (_, i) => ((i + 1) % 12 === 0 ? ((i + 1) / 12).toString() : ''));
export const mapChart = ['子どもの発', '保育園'];
const numofEnjoji = enjojistyles.length;

export const processData = (data: ChartResult): ChartData => {
  return {
    currentCity: data.enjoji.current_city.name,
    ages: processAge(data),
    childDevelopments: processChildDevelopment(data),
    nurserySchools: processNurserySchool(data),
    enjoji: processEnjoji(data),
    comprehensiveEvaluation: processComprehensiveEvaluation(data),
    comments: data.comments.map((x) => ({ ...x, comments: x.comments.filter((x) => x && x.trim().length > 0) }))
  };
};

const processComprehensiveEvaluation = (data: ChartResult) => {
  let childDevelopments = [];
  let nurserySchool = [];
  let comprehensive = [];

  for (let [school_name, value] of Object.entries(data.school_info_regional)) {
    const sumChild = data.child_development_regional[school_name].reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    const sumNursery = data.nursery_schools_regional[school_name].reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    childDevelopments.push({
      ...value,
      school_name: school_name,
      value: sumChild
    });

    nurserySchool.push({
      ...value,
      school_name: school_name,
      value: sumNursery
    });

    comprehensive.push({
      ...value,
      school_name: school_name,
      value: sumChild + sumNursery
    });
  }

  comprehensive = comprehensive.sort((a, b) => {
    return b.value - a.value;
  });

  return {
    childDevelopments: childDevelopments,
    nurserySchool: nurserySchool,
    comprehensive: comprehensive,
    comprehensiveChart: {
      schools: comprehensive.map((item) => item.school_name),
      values: [
        {
          name: '子どもの発',
          type: 'bar',
          data: comprehensive.map((item) => {
            return data.child_development_regional[item.school_name].reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            );
          })
        },
        {
          name: '保育園',
          type: 'bar',
          data: comprehensive.map((item) => {
            return data.nursery_schools_regional[item.school_name].reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            );
          })
        }
      ]
    }
  };
};

const processEnjoji = (data: ChartResult) => {
  const current_city: any = [];
  const all_cities: any = [];
  enjojistyles.forEach((element, index) => {
    current_city.push([
      {
        name: '発達年齢',
        type: 'bar',
        data: data.enjoji.current_city.data[index + 1]
      },
      {
        name: '実際の年齢',
        type: 'bar',
        data: data.enjoji.current_city.data[0]
      }
    ]);
  });
  data.enjoji.all_cities.forEach((item: any) => {
    all_cities.push({
      cities: item.cities,
      data: [
        {
          name: '発達年齢',
          data: item.data
        }
      ]
    });
  });
  return {
    current_city,
    all_cities
  };
};
const processAge = (data: ChartResult) => {
  const currentCity = data.enjoji.current_city.name;
  const currentCityAges = data.ages[currentCity] || {
    developmental_ages: [[], [], [], [], []],
    actual_ages: []
  };

  return {
    seriesCurrentCity: currentCityAges.developmental_ages.map((value, index) => {
      const enjojiColumns = enjojistyles.map((enjoji, idx) => ({
        name: enjoji,
        type: 'bar',
        data: Array.from({ length: numofEnjoji }, (_, i) => (i === idx ? value[i] || 0 : 0))
      }));
      const actualAgeColumn = {
        name: '実際の年齢',
        type: 'line',
        data: Array(numofEnjoji).fill(currentCityAges.actual_ages[index] || 0)
      };
      enjojiColumns.push(actualAgeColumn);
      return enjojiColumns;
    }),
    seriesAllCities: ages.map((age, ageIndex) => {
      const sortedCities = Object.entries(data.ages).sort((cityA, cityB) => {
        const cityAScore = cityA[1].developmental_ages[ageIndex].reduce((acc, cur) => acc + cur);
        const cityBScore = cityB[1].developmental_ages[ageIndex].reduce((acc, cur) => acc + cur);
        return cityBScore - cityAScore;
      });
      return {
        cities: sortedCities.map(([city]) => city),
        values: enjojistyles.map((enjoji, enjojiIndex) => {
          return {
            name: enjoji,
            type: 'bar',
            data: sortedCities.map(([_, city]) => city.developmental_ages[ageIndex][enjojiIndex])
          };
        })
      };
    })
  };
};

const processChildDevelopment = (data: ChartResult) => {
  // Sort by total scores
  const sortedSchools = Object.entries(data.child_development_regional).sort((schoolA, schoolB) => {
    const schoolAScore = schoolA[1].reduce((acc, cur) => acc + cur);
    const schoolBScore = schoolB[1].reduce((acc, cur) => acc + cur);
    return schoolBScore - schoolAScore;
  });
  const seriesAllItems = {
    schools: sortedSchools.map(([school]) => school),
    values: enjojistyles.map((enjoji, enjojiIndex) => {
      return {
        name: enjoji,
        type: 'bar',
        data: sortedSchools.map(([_, school]) => school[enjojiIndex])
      };
    })
  };

  const seriesPerItems = enjojistyles.map((enjoji, enjojiIndex) => {
    // Sort by each item
    const sortedSchools = Object.entries(data.child_development_regional).sort((schoolA, schoolB) => {
      const schoolAScore = schoolA[1][enjojiIndex];
      const schoolBScore = schoolB[1][enjojiIndex];
      return schoolBScore - schoolAScore;
    });
    return {
      schools: sortedSchools.map(([school]) => school),
      values: [
        {
          name: enjoji,
          type: 'bar',
          data: sortedSchools.map(([school, value]) => value[enjojiIndex])
        }
      ]
    };
  });

  return {
    seriesAllItems,
    seriesPerItems
  };
};

const processNurserySchool = (data: ChartResult) => {
  // Sort by total scores
  const sortedSchools = Object.entries(data.nursery_schools_regional).sort((schoolA, schoolB) => {
    const schoolAScore = schoolA[1].reduce((acc, cur) => acc + cur);
    const schoolBScore = schoolB[1].reduce((acc, cur) => acc + cur);
    return schoolBScore - schoolAScore;
  });

  const seriesAllItems = {
    schools: sortedSchools.map(([school]) => school),
    values: schoolEvaluation.map((nursery, index) => {
      return {
        name: nursery,
        type: 'bar',
        data: sortedSchools.map(([_, school]) => school[index])
      };
    })
  };

  const seriesPerItems = schoolEvaluation.map((nursery, index) => {
    // Sort by each item
    const sortedSchools = Object.entries(data.nursery_schools_regional).sort((schoolA, schoolB) => {
      const schoolAScore = schoolA[1][index];
      const schoolBScore = schoolB[1][index];
      return schoolBScore - schoolAScore;
    });
    return {
      schools: sortedSchools.map(([school]) => school),
      values: [
        {
          name: nursery,
          type: 'bar',
          data: sortedSchools.map(([school, value]) => value[index])
        }
      ]
    };
  });

  return {
    seriesAllItems,
    seriesPerItems
  };
};
