import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAsqRespondentChoices, getAsqSurveyList, postAsqSurvey } from 'helpers/backend-helper';
import { useEffect, useMemo, useState } from 'react';
import {
  DailyActivityResponseSectionPostData,
  OverallEvaluationResponsePostData,
  RespondentChoice,
  RespondentFormData,
  RespondentPostData,
  Survey,
  SurveyItem
} from '../../../types/asq';
import { toast } from 'react-toastify';
import { SelectAsqSurveyField } from '../../../components/Asq/FormField/SelectAsqSurveyField';
import { QuestionContainer } from '../../../components/Asq/Containers/QuestionContainer';
import { FlexColumnItemContainer } from '../../../components/Asq/Containers/FlexColumnItemContainer';
import Title from '../../../components/Title';
import { Respondent } from '../../../components/Asq/Containers/Respondent';
import { DailyActivityContainer } from '../../../components/Asq/Containers/DailyActivity';
import { OverallEvaluationContainer } from '../../../components/Asq/Containers/OverallEvaluation';
import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const DEFAULT_DAILY_ACTIVITY_RESPONSE_GROUP_VALUES: {
  sections: DailyActivityResponseSectionPostData[];
} = {
  sections: []
};

const DEFAULT_OVERALL_EVALUATION_RESPONSE_GROUP_VALUES: {
  responses: OverallEvaluationResponsePostData[];
} = {
  responses: []
};

const DEFAULT_RESPONDENT_VALUES: RespondentFormData = {
  last_name_of_child: '',
  first_name_of_child: '',
  birth_date_of_child: {
    startDate: undefined,
    endDate: undefined
  },
  gestational_age_of_child: undefined,
  sex_of_child: '',
  filler_last_name: '',
  filler_first_name: '',
  filler_relationship_to_child: '',
  filler_relationship_to_child_detail: '',
  filler_postal_code: '',
  filler_address: '',
  filler_phone_number: '',
  filler_email: '',
  assisted_by: '',
  age_in_months: undefined,
  age_in_days: undefined,
  corrected_age_in_months: undefined,
  corrected_age_in_days: undefined,
  daily_activity_response_group: DEFAULT_DAILY_ACTIVITY_RESPONSE_GROUP_VALUES,
  overall_evaluation_response_group: DEFAULT_OVERALL_EVALUATION_RESPONSE_GROUP_VALUES
};

const birthDateOfChildSchema = yup.object().shape({
  startDate: yup
    .date()
    .test('startDate', '生年月日を入力してください', (value) => value !== undefined && value !== null)
    .max(new Date(), '未来の日付は入力できません'),
  endDate: yup
    .date()
    .test('endDate', '生年月日を入力してください', (value) => value !== undefined && value !== null)
    .max(new Date(), '未来の日付は入力できません')
    .min(yup.ref('startDate'), '開始日より前の日付は入力できません')
});

const dailyActivityResponseSchema = yup.object().shape({
  question: yup.number().required(),
  choice: yup.number(),
  additional_answer: yup.string()
});

const dailyActivityResponseSectionSchema = yup.object().shape({
  section: yup.number().required(),
  responses: yup.array().of(dailyActivityResponseSchema).required()
});

const dailyActivityResponseGroupSchema = yup.object().shape({
  sections: yup.array().of(dailyActivityResponseSectionSchema).required()
});

const overallEvaluationResponseSchema = yup.object().shape({
  question: yup.number().required(),
  choice: yup.number(),
  // choice が choice_for_detail で指定された「はい」か「いいえ」の場合、
  // detail は必須だがそのバリデーションをここで書くのは難しいので、onError でバリデーションする
  detail: yup.string()
});

const overallEvaluationResponseGroupSchema = yup.object().shape({
  responses: yup.array().of(overallEvaluationResponseSchema).required()
});
const validationSchema = yup.object().shape({
  last_name_of_child: yup.string(),
  first_name_of_child: yup.string(),
  birth_date_of_child: birthDateOfChildSchema.required('生年月日を入力してください'),
  gestational_age_of_child: yup
    .number()
    .positive('在胎週数は正の数を入力してください')
    .integer('在胎週数は整数を入力してください'), // 在胎週数
  sex_of_child: yup.string().required('性別を選択してください'),
  filler_last_name: yup.string(),
  filler_first_name: yup.string(),
  filler_relationship_to_child: yup.string().required('お子さんとの関係を選択してください'),
  // filler_relationship_to_child_detail はもし filler_relationship_to_child が「その他」の場合に必須
  filler_relationship_to_child_detail: yup.string().when('filler_relationship_to_child', {
    is: 'other',
    then: (schema) => schema.required('お子さんとの関係を入力してください'),
    otherwise: (schema) => schema
  }),
  // 郵便番号
  filler_postal_code: yup.string().test('postalCode', '郵便番号は7桁の数字で入力してください', (value) => {
    if (!value) return true;
    return /^\d{7}$/.test(value);
  }),
  filler_address: yup.string(),
  filler_phone_number: yup.string().test('phoneNumber', '電話番号の10か11桁の数字で入力してください', (value) => {
    if (!value) return true;
    return /^0\d{9,10}$/.test(value);
  }),
  filler_email: yup
    .string()
    .email('メールアドレスの形式が正しくありません')
    .required('メールアドレスを入力してください'),
  assisted_by: yup.string(),
  age_in_months: yup
    .number()
    .test('required', '月齢を入力してください', (value) => {
      return value !== undefined;
    })
    .positive('月齢は正の数を入力してください')
    .integer('月齢は整数を入力してください'),
  age_in_days: yup
    .number()
    .test('required', '日齢を入力してください', (value) => {
      return value !== undefined;
    })
    .positive('日齢は正の数を入力してください')
    .integer('日齢は整数を入力してください'),
  corrected_age_in_months: yup
    .number()
    .positive('月齢は正の数を入力してください')
    .integer('月齢は整数を入力してください'),
  corrected_age_in_days: yup
    .number()
    .positive('日齢は正の数を入力してください')
    .integer('日齢は整数を入力してください'),
  daily_activity_response_group: dailyActivityResponseGroupSchema.required(),
  overall_evaluation_response_group: overallEvaluationResponseGroupSchema.required()
});

const title = 'Ages & Stages Questionnaires (ASQ)';

export const AsqSurveyPage = () => {
  const [surveyList, setSurveyList] = useState<SurveyItem[]>();
  const [surveyDetail, setSurveyDetail] = useState<Survey>();
  const [respondentChoice, setRespondentChoice] = useState<RespondentChoice>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const {
    setValue,
    setError,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm<RespondentFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: DEFAULT_RESPONDENT_VALUES
  });

  const currentFormData = watch();

  /**
   * サーバーサイドから提供される質問データ surveyDetail に基づいて、回答データの初期値を生成する。
   * surveyDetail が変更されたときのみ再計算する。
   */
  const respondentValues = useMemo(() => {
    if (!surveyDetail) return DEFAULT_RESPONDENT_VALUES;
    return {
      ...currentFormData, // 現在の値を保持
      daily_activity_response_group: {
        sections: surveyDetail.daily_activity.sections.map((section) => {
          return {
            section: section.id,
            responses: section.questions.map((question) => {
              return {
                question: question.id,
                choice: undefined,
                additional_answer: ''
              };
            })
          };
        })
      },
      overall_evaluation_response_group: {
        responses: surveyDetail.overall_evaluation.questions.map((question) => {
          return {
            question: question.id,
            choice: undefined,
            detail: ''
          };
        })
      }
    };
  }, [surveyDetail]);

  const fetchSurveyList = async () => {
    try {
      const sl = await getAsqSurveyList();
      setSurveyList(sl);
    } catch (e: any) {
      toast.error('ASQのデータ取得中にエラーが発生しました');
    }
  };
  const fetchRespondentChoices = async () => {
    try {
      const rc = await getAsqRespondentChoices();
      setRespondentChoice(rc);
    } catch (e: any) {
      toast.error('ASQ回答者選択肢のデータ取得中にエラーが発生しました');
    }
  };

  /**
   * ページ表示時にASQのデータを取得する
   */
  useEffect(() => {
    fetchSurveyList();
    fetchRespondentChoices();
  }, []);

  /**
   * ASQのデータが取得できたら、フォームフィールドをデフォルトからリセットする
   */
  useEffect(() => {
    reset(respondentValues);
  }, [reset, respondentValues]);

  const onSubmit = async (data: RespondentFormData) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    // YYYY-MM-DD の形式に変換
    const postData: RespondentPostData = {
      ...data,
      birth_date_of_child: dayjs(currentFormData.birth_date_of_child.startDate).format('YYYY-MM-DD')
    };

    try {
      await postAsqSurvey(postData);
      navigate('/submit-success');
    } catch (e: any) {
      // TODO バックエンドのエラーハンドリングを実装する
      console.error(e);
    }
    setIsSubmitting(false);
  };

  const onError = () => {
    if (!surveyDetail) return;

    const overallEvaluationResponses = currentFormData.overall_evaluation_response_group.responses;
    const overallEvaluationQuestions = surveyDetail.overall_evaluation.questions;

    // overall_evaluation の choice が choice_for_detail で指定された「はい」か「いいえ」の場合、detail は必須
    overallEvaluationResponses.forEach((response, index) => {
      if (response.choice === overallEvaluationQuestions[index].choice_for_detail && !response.detail) {
        setError(`overall_evaluation_response_group.responses.${index}.detail`, {
          type: 'required',
          message: '詳細を入力してください'
        });
      }
    });

    console.log(errors);
  };

  // 質問データをフェッチしてformにセットして質問群を表示できるかのフラグ
  const isReady = useMemo(() => {
    return (
      surveyDetail!! &&
      currentFormData.overall_evaluation_response_group.responses.length > 0 &&
      currentFormData.daily_activity_response_group.sections.length > 0
    );
  }, [
    currentFormData.daily_activity_response_group.sections.length,
    currentFormData.overall_evaluation_response_group.responses.length,
    surveyDetail
  ]);

  return (
    <div className='container mx-auto py-10 flex flex-col items-center gap-6 sm:gap-10'>
      <div className='w-[80%] md:w-full'>
        <Title title={title} />
      </div>
      <div className='md:px-10 px-4 w-full mx-auto'>
        <div className='flex flex-col gap-6 sm:gap-10'>
          {/*select ASQ*/}
          <FlexColumnItemContainer>
            <QuestionContainer questionText={'対象月齢を選択してください'} required={false}>
              <SelectAsqSurveyField
                surveyList={surveyList}
                setSurveyDetail={setSurveyDetail}
                surveyDetail={surveyDetail}
              />
            </QuestionContainer>
          </FlexColumnItemContainer>

          {/*show selected ASQ detail*/}
          {isReady && (
            <>
              <Respondent
                currentFormValues={currentFormData}
                setValue={setValue}
                errors={errors}
                respondentChoice={respondentChoice}
              />
              <DailyActivityContainer
                key={'daily_activity'}
                dailyActivity={surveyDetail!.daily_activity}
                setValue={setValue}
                currentFormData={currentFormData}
                formErrors={errors}
              />
              <OverallEvaluationContainer
                key={'overall_evaluation'}
                overallEvaluation={surveyDetail!.overall_evaluation}
                setValue={setValue}
                currentFormData={currentFormData}
                formErrors={errors}
              />
            </>
          )}
          {
            /* errors オブジェクト に中身があったら表示 */
            Object.keys(errors).length > 0 && (
              <div className='mt-2 text-sm text-red'>入力エラーがあります。必須項目を入力してください。</div>
            )
          }
          {surveyDetail && (
            <div className='w-full flex justify-center'>
              <Button title='送信' onClick={handleSubmit(onSubmit, onError)} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
