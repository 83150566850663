import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { useMemo } from 'react';
import { RespondentChoice, RespondentFormData } from '../../../../types/asq';
import { OptionItem } from '../../../../types/components/common/select';
import { FlexColumnItemContainer } from '../FlexColumnItemContainer';
import { Tag } from '../../../Common/Tag';
import { GridContainer } from '../GridContainer';
import { QuestionContainer } from '../QuestionContainer';
import { TextInputField } from '../../FormField/TextInputField';
import { DatepickerField } from '../../FormField/DatepickerField';
import { NumberInputField } from '../../FormField/NumberInputField';
import { RadioGroupField } from '../../FormField/RadioGroupField';

interface Props {
  currentFormValues: RespondentFormData;
  setValue: UseFormSetValue<RespondentFormData>;
  errors: FieldErrors<RespondentFormData>;
  respondentChoice?: RespondentChoice;
}

export const Respondent = ({ setValue, errors, currentFormValues, respondentChoice }: Props) => {
  const sexOptions: OptionItem[] = useMemo(() => {
    if (!respondentChoice) return [];
    return respondentChoice.sex;
  }, [respondentChoice]);

  const relationshipOptions: OptionItem[] = useMemo(() => {
    if (!respondentChoice) return [];
    return respondentChoice.relationship;
  }, [respondentChoice]);

  const ageInMonthAndDay = useMemo(() => {
    const month = currentFormValues.age_in_months ?? 0;
    const day = currentFormValues.age_in_days ?? 0;
    return `${month}ヶ月${day}日`;
  }, [currentFormValues.age_in_days, currentFormValues.age_in_months]);

  const correctedAgeInMonthAndDay = useMemo(() => {
    const month = currentFormValues.corrected_age_in_months ?? 0;
    const day = currentFormValues.corrected_age_in_days ?? 0;
    return `${month}ヶ月${day}日`;
  }, [currentFormValues.corrected_age_in_days, currentFormValues.corrected_age_in_months]);

  return (
    <FlexColumnItemContainer className={'rounded-lg bg-white sm:p-8 p-4 flex flex-col gap-8'}>
      <span className='font-semibold sm:text-xl text-lg'>基本情報記入紙</span>
      <Tag>お子さんの情報</Tag>
      <GridContainer>
        <QuestionContainer questionText={'姓'}>
          <TextInputField
            value={currentFormValues.last_name_of_child}
            setValue={setValue}
            formErrors={errors}
            fieldKey={'last_name_of_child'}
            placeholder={'姓'}
          />
        </QuestionContainer>
        <QuestionContainer questionText={'名'}>
          <TextInputField
            value={currentFormValues.first_name_of_child}
            setValue={setValue}
            formErrors={errors}
            fieldKey={'first_name_of_child'}
            placeholder={'名'}
          />
        </QuestionContainer>
        <QuestionContainer questionText={'生年月日'} required={true}>
          <DatepickerField
            value={currentFormValues.birth_date_of_child}
            formErrors={errors}
            datepickerProps={{
              i18n: 'ja',
              asSingle: true,
              useRange: false,
              placeholder: '生年月日',
              inputClassName: `w-full bg-white rounded-md border py-2 px-5 text-black outline-none transition disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2`,
              maxDate: new Date(),
              readOnly: true
            }}
            setValue={setValue}
            fieldKey={'birth_date_of_child'}
          />
        </QuestionContainer>
        <QuestionContainer questionText={'在胎週数'}>
          <NumberInputField
            value={currentFormValues.gestational_age_of_child}
            setValue={setValue}
            formErrors={errors}
            fieldKey={'gestational_age_of_child'}
            placeholder={'在胎週数'}
          />
        </QuestionContainer>
        <QuestionContainer questionText={'性別'} required={true}>
          <RadioGroupField
            setValue={setValue}
            value={currentFormValues.sex_of_child}
            formErrors={errors}
            fieldKey={'sex_of_child'}
            options={sexOptions}
          />
        </QuestionContainer>
      </GridContainer>

      <Tag>記入者の情報</Tag>
      <GridContainer>
        <QuestionContainer questionText={'姓'}>
          <TextInputField
            value={currentFormValues.filler_last_name}
            setValue={setValue}
            formErrors={errors}
            fieldKey={'filler_last_name'}
            placeholder={'姓'}
          />
        </QuestionContainer>
        <QuestionContainer questionText={'名'}>
          <TextInputField
            value={currentFormValues.filler_first_name}
            setValue={setValue}
            formErrors={errors}
            fieldKey={'filler_first_name'}
            placeholder={'名'}
          />
        </QuestionContainer>
        <QuestionContainer questionText={'お子さんとの関係'} required={true}>
          <RadioGroupField
            setValue={setValue}
            value={currentFormValues.filler_relationship_to_child}
            formErrors={errors}
            fieldKey={'filler_relationship_to_child'}
            options={relationshipOptions}
          />
        </QuestionContainer>

        {currentFormValues.filler_relationship_to_child === 'other' && (
          <QuestionContainer questionText={'その他の場合'} required={true}>
            <TextInputField
              value={currentFormValues.filler_relationship_to_child_detail}
              setValue={setValue}
              formErrors={errors}
              fieldKey={'filler_relationship_to_child_detail'}
              placeholder={'その他の場合'}
            />
          </QuestionContainer>
        )}
        <QuestionContainer questionText={'郵便番号'}>
          <TextInputField
            value={currentFormValues.filler_postal_code}
            setValue={setValue}
            formErrors={errors}
            fieldKey={'filler_postal_code'}
            placeholder={'郵便番号'}
          />
        </QuestionContainer>
        <QuestionContainer questionText={'住所'}>
          <TextInputField
            value={currentFormValues.filler_address}
            setValue={setValue}
            formErrors={errors}
            fieldKey={'filler_address'}
            placeholder={'住所'}
          />
        </QuestionContainer>
        <QuestionContainer questionText={'電話番号'}>
          <TextInputField
            value={currentFormValues.filler_phone_number}
            setValue={setValue}
            formErrors={errors}
            fieldKey={'filler_phone_number'}
            placeholder={'電話番号'}
          />
        </QuestionContainer>
        <QuestionContainer questionText={'メールアドレス'} required={true}>
          <TextInputField
            value={currentFormValues.filler_email}
            setValue={setValue}
            formErrors={errors}
            fieldKey={'filler_email'}
            placeholder={'メールアドレス'}
          />
        </QuestionContainer>
        <QuestionContainer questionText={'記入者'}>
          <TextInputField
            value={currentFormValues.assisted_by}
            setValue={setValue}
            formErrors={errors}
            fieldKey={'assisted_by'}
            placeholder={'記入者'}
          />
        </QuestionContainer>
        <QuestionContainer questionText={'お子さんの月齢と日齢'} required={true}>
          <NumberInputField
            value={currentFormValues.age_in_months}
            setValue={setValue}
            formErrors={errors}
            fieldKey={'age_in_months'}
            placeholder={'お子さんの月齢'}
          />
          <NumberInputField
            value={currentFormValues.age_in_days}
            setValue={setValue}
            formErrors={errors}
            fieldKey={'age_in_days'}
            placeholder={'お子さんの日齢'}
          />
          <p>{ageInMonthAndDay}</p>
        </QuestionContainer>
        <QuestionContainer questionText={'お子さんの修正月齢と日齢'}>
          <NumberInputField
            value={currentFormValues.corrected_age_in_months}
            setValue={setValue}
            formErrors={errors}
            fieldKey={'corrected_age_in_months'}
            placeholder={'お子さんの修正月齢'}
          />
          <NumberInputField
            value={currentFormValues.corrected_age_in_days}
            setValue={setValue}
            formErrors={errors}
            fieldKey={'corrected_age_in_days'}
            placeholder={'お子さんの修正日齢'}
          />
          <p>{correctedAgeInMonthAndDay}</p>
        </QuestionContainer>
      </GridContainer>
    </FlexColumnItemContainer>
  );
};
