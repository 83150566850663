import configs from 'configs';
import GoogleMapReact from 'google-map-react';
import { useEffect, useState } from 'react';
import close from 'assets/images/close.svg';

interface IMarker {
  school_name: string;
  latitude: number;
  longitude: number;
  school_size: number;
  value: number;
}

interface IMap {
  data: IMarker[];
}

interface IMarkerComponent {
  text: string;
  zoom: number;
  size: number;
  score: number;
  isSelected: boolean;
  lat: number;
  lng: number;
  firstRange: number;
  lastRange: number;
  onClick: () => void;
  onCloseTooltip: () => void;
}

const schoolSize = ['10', '10-20', '20-30', '30'];

const Marker = ({
  text,
  zoom,
  size,
  score,
  isSelected,
  onClick,
  firstRange,
  lastRange,
  onCloseTooltip
}: IMarkerComponent) => {
  const [color, setColor] = useState<string>('');

  useEffect(() => {
    if (!score) {
      setColor('gray');
      return;
    }

    if (score <= firstRange) {
      setColor('red');
    }

    if (score >= lastRange) {
      setColor('green');
    }

    if (score > firstRange && score < lastRange) {
      setColor('yellow');
    }
  }, [score]);

  return (
    <div style={{ position: 'absolute' }}>
      <div
        style={{
          transform: `translate(-50%, -50%) scaleX(${((size || 1) * 2) / 10 + 0.3}) scaleY(${
            ((size || 1) * 2) / 10 + 0.3
          })`,
          cursor: 'pointer',
          backgroundColor: `${color}`
        }}
        onClick={onClick}
        className={`rounded-full w-${zoom > 10 ? 10 : zoom} h-${zoom > 10 ? 10 : zoom}`}
      ></div>
      {isSelected && (
        <div
          style={{
            position: 'absolute',
            top: '-100%', // Adjust the distance above the marker
            left: '-50%',
            background: 'white',
            padding: '12px 20px 8px',
            borderRadius: '5px',
            boxSizing: 'border-box',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            zIndex: 1,
            width: 'max-content',
            maxWidth: '150px'
          }}
        >
          <img
            src={close}
            className='absolute right-1 top-1 cursor-default w-[10px] h-[10px]'
            alt='Fvital'
            onClick={onCloseTooltip}
          />
          <p className='font-bold pb-1'>{text}</p>
          <p className='pb-1'>点数: {score.toFixed(2)}</p>
          <p>サイズ: {schoolSize[size - 1] || '情報無し'}</p>
        </div>
      )}
    </div>
  );
};

const MapComponent = ({ data }: IMap) => {
  const [zoom, setZoom] = useState<number>(10);
  const [listMarkers, setListMarkers] = useState<IMarker[]>(
    data.sort((a, b) => {
      return b.value - a.value;
    })
  );
  const [firstRange, setFirstRange] = useState<number>();
  const [lastRange, setLastRange] = useState<number>();

  useEffect(() => {
    if (listMarkers && listMarkers.length > 0) {
      const firstRange = (listMarkers[0].value + listMarkers[listMarkers.length - 1].value) / 3;

      const lastRange = firstRange * 2;

      setFirstRange(firstRange);
      setLastRange(lastRange);
    }
  }, [listMarkers]);

  const handleMapChange = (event: any) => {
    const currentZoom = event.zoom;
    setZoom(currentZoom);
  };

  const handleShowInfo = (index: number, listMarkers: any, setListMarkers: any) => {
    let list = [...listMarkers];
    list = list.map((item, indexM) =>
      indexM === index ? { ...item, isSelected: !list[index].isSelected } : { ...item, isSelected: false }
    );
    setListMarkers(list);
  };

  const handleCloseTooltip = (listMarkers: any, setListMarkers: any) => {
    let list = [...listMarkers];
    list = list.map((item) => {
      return { ...item, isSelected: false };
    });
    setListMarkers(list);
  };

  return (
    <div className='h-[500px] w-full'>
      <GoogleMapReact
        bootstrapURLKeys={{ key: configs.GG_API_KEY || '' }}
        defaultZoom={zoom}
        defaultCenter={{ lat: listMarkers[0]?.latitude, lng: listMarkers[0]?.longitude }}
        onChange={handleMapChange}
      >
        {firstRange !== undefined &&
          lastRange !== undefined &&
          listMarkers.map((item: any, index: number) => (
            <Marker
              text={item.school_name}
              score={item.value}
              key={index}
              zoom={zoom}
              size={item.school_size}
              isSelected={item.isSelected}
              lat={item.latitude}
              lng={item.longitude}
              firstRange={firstRange}
              lastRange={lastRange}
              onClick={() => handleShowInfo(index, listMarkers, setListMarkers)}
              onCloseTooltip={() => handleCloseTooltip(listMarkers, setListMarkers)}
            />
          ))}
      </GoogleMapReact>
    </div>
  );
};

export default MapComponent;
