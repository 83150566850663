import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { RespondentFormData } from '../../../types/asq';
import NumberInput from '../../Common/NumberInput';

interface Props {
  value?: number | null;
  setValue: UseFormSetValue<RespondentFormData>;
  formErrors: FieldErrors<RespondentFormData>;
  fieldKey: keyof RespondentFormData;
  placeholder?: string;
}

export const NumberInputField = ({ value, setValue, fieldKey, placeholder, formErrors }: Props) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.value === '') {
      // 入力を全消ししたとき
      setValue(fieldKey, undefined);
      return;
    }
    // 文字列の数字のみ渡ってくる
    const parsedValue = parseInt(event.target.value);
    if (parsedValue === value) return;
    setValue(fieldKey, parsedValue);
  };

  const handleOnSpin = (type: 'increase' | 'decrease') => {
    let newValue = value ?? 0;
    if (type === 'increase') {
      newValue++;
    } else if (type === 'decrease') {
      if (newValue < 1) {
        setValue(fieldKey, undefined);
        return;
      }
      newValue--;
    }
    setValue(fieldKey, newValue);
  };

  const errorMessage = formErrors[fieldKey]?.message;

  return (
    <NumberInput
      label=''
      value={value ? value.toString() : ''}
      onChange={handleOnChange}
      onBlur={handleOnChange}
      spinHandler={handleOnSpin}
      className='w-full'
      placeholder={placeholder}
      errorMessage={errorMessage}
    />
  );
};
