import Spinner from 'components/Common/Spinner';
import React, { FC, ReactNode } from 'react';

interface LoadingWrapperProps {
  children: ReactNode;
  isLoading: boolean;
  isEmpty?: boolean;
  contentClassName?: string;
  emptyText?: string;
  loadingClassName?: string;
  loadingComponent?: ReactNode;
}

const LoadingWrapper: FC<LoadingWrapperProps> = ({
  children,
  isLoading,
  loadingComponent,
  isEmpty = false,
  contentClassName = '',
  loadingClassName = '',
  emptyText = '検索結果がありません。'
}) => {
  if (isLoading) {
    if (loadingComponent) return <>{loadingComponent}</>;

    return (
      <div className={`flex justify-center ${loadingClassName}`}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={contentClassName}>
      {isEmpty ? <p className='text-center text-gray-900'>{emptyText}</p> : children}
    </div>
  );
};

export default LoadingWrapper;
