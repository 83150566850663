import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { OverallEvaluation, RespondentFormData } from '../../../../types/asq';
import React, { ComponentProps, useMemo } from 'react';
import { FlexColumnItemContainer } from '../FlexColumnItemContainer';
import { OverallEvaluationQuestionContainer } from './OverallEvaluationQuestionContainer';

interface Props extends ComponentProps<typeof FlexColumnItemContainer> {
  overallEvaluation: OverallEvaluation;
  setValue: UseFormSetValue<RespondentFormData>;
  currentFormData: RespondentFormData;
  formErrors: FieldErrors<RespondentFormData>;
}

export const OverallEvaluationContainer = ({
  overallEvaluation,
  currentFormData,
  setValue,
  formErrors,
  ...rest
}: Props) => {
  const overallEvaluationQuestionOptions = useMemo(() => {
    return overallEvaluation.choices;
  }, [overallEvaluation]);
  if (!overallEvaluation) return null;

  return (
    <FlexColumnItemContainer className={'flex flex-col gap-8'} {...rest}>
      <FlexColumnItemContainer className={'rounded-lg bg-white sm:p-8 p-4 flex flex-col gap-8'}>
        <p className='font-semibold'>総合的評価</p>
        <p className='font-semibold'>{overallEvaluation.question_text}</p>
        {overallEvaluation.note && <p className='text-sm text-gray-500'>{overallEvaluation.note}</p>}
      </FlexColumnItemContainer>
      {overallEvaluation.questions.map((question, questionIndex) => {
        return (
          <OverallEvaluationQuestionContainer
            key={`question-${question.id}`}
            note={question.note}
            questionText={question.question_text}
            questionIndex={questionIndex + 1}
            options={overallEvaluationQuestionOptions}
            setValue={setValue}
            nestedFieldKey={`responses.${questionIndex}`}
            radioValue={currentFormData.overall_evaluation_response_group.responses[questionIndex]?.choice}
            textValue={currentFormData.overall_evaluation_response_group.responses[questionIndex]?.detail}
            choiceForDetail={question.choice_for_detail}
            formErrors={formErrors}
            className={'rounded-lg bg-white sm:p-8 p-4 flex flex-col gap-4'}
          />
        );
      })}
    </FlexColumnItemContainer>
  );
};
