import { InputProps, TextInput } from '../../Common/TextInput';
import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { RespondentFormData } from '../../../types/asq';
import { undefinedToEmptyString } from '../../../helpers/yup-helpers';
import { useMemo } from 'react';

interface Props extends Omit<InputProps, 'label'> {
  setValue: UseFormSetValue<RespondentFormData>;
  value?: string;
  formErrors: FieldErrors<RespondentFormData>;
  fieldKey: keyof RespondentFormData;
  /**
   * nestedFieldKey is required when fieldKey is 'daily_activity_response_group'
   * e.g. 'sections.0.responses.0.choice'
   */
  nestedFieldKey?: string;
}

function validateDailyActivityKey(nestedFieldKey?: string): void {
  if (!nestedFieldKey) throw new Error('nestedFieldKey is required');
  const regex = /^sections\.\d+\.responses\.\d+\.additional_answer$/;
  if (!regex.test(nestedFieldKey)) throw new Error(`Invalid nestedFieldKey: ${nestedFieldKey}`);
}

function validateOverallEvaluationKey(nestedFieldKey?: string): void {
  if (!nestedFieldKey) throw new Error('nestedFieldKey is required');
  const regex = /^responses\.\d+\.detail$/;
  if (!regex.test(nestedFieldKey)) throw new Error(`Invalid nestedFieldKey: ${nestedFieldKey}`);
}

export const TextAreaInputField = ({ value, setValue, formErrors, fieldKey, nestedFieldKey, ...rest }: Props) => {
  const setDailyActivityValue = (newValue: string) => {
    validateDailyActivityKey(nestedFieldKey);
    const indexes = nestedFieldKey!.split('.').map((v) => v);
    const sectionIndex = Number(indexes[1]);
    const responseIndex = Number(indexes[3]);
    setValue(
      `daily_activity_response_group.sections.${sectionIndex}.responses.${responseIndex}.additional_answer`,
      newValue
    );
  };

  const setOverallEvaluationValue = (newValue: string) => {
    validateOverallEvaluationKey(nestedFieldKey);
    const indexes = nestedFieldKey!.split('.').map((v) => v);
    const questionIndex = Number(indexes[1]);
    setValue(`overall_evaluation_response_group.responses.${questionIndex}.detail`, newValue);
  };
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (fieldKey === 'daily_activity_response_group') {
      setDailyActivityValue(event.target.value);
      return;
    }
    if (fieldKey === 'overall_evaluation_response_group') {
      setOverallEvaluationValue(event.target.value);
      return;
    }
    setValue(fieldKey, event.target.value);
  };

  const errorMessage = useMemo(() => {
    if (fieldKey === 'daily_activity_response_group') {
      validateDailyActivityKey(nestedFieldKey);
      const indexes = nestedFieldKey!.split('.').map((v) => v);
      const sectionIndex = Number(indexes[1]);
      const responseIndex = Number(indexes[3]);
      return formErrors.daily_activity_response_group?.sections?.[sectionIndex]?.responses?.[responseIndex]
        ?.additional_answer?.message;
    }
    if (fieldKey === 'overall_evaluation_response_group') {
      validateOverallEvaluationKey(nestedFieldKey);
      const indexes = nestedFieldKey!.split('.').map((v) => v);
      const questionIndex = Number(indexes[1]);
      return formErrors.overall_evaluation_response_group?.responses?.[questionIndex]?.detail?.message;
    }
    return formErrors[fieldKey]?.message;
  }, [fieldKey, formErrors, nestedFieldKey]);

  return (
    <TextInput
      label=''
      textarea
      value={undefinedToEmptyString(value)}
      onChange={handleOnChange}
      className='w-full'
      errorMessage={errorMessage}
      {...rest}
    />
  );
};
