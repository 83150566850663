import { SvgProps } from 'components/Common/Icon';
import React, { FC } from 'react';

interface SessionTypeProps {
  IconComponent: FC<SvgProps>;
  label: string;
  onClick: () => void;
}

const SessionType: FC<SessionTypeProps> = ({ IconComponent, label, onClick }) => {
  return (
    <div
      onClick={onClick}
      className='bg-white p-10 sm:w-[360px] sm:h-[200px] w-[340px] h-[180px]
      shadow-[rgba(17,_17,_26,_0.1)_0px_0px_6px]
      ring-2 ring-gray-medium rounded-lg flex flex-col items-start justify-center hover:ring-2 hover:ring-primary hover:bg-blue-50 hover:scale-105 transition hover:cursor-pointer group'
    >
      <IconComponent width={70} height={70} className={`group-hover:fill-primary fill-gray-700`} />
      <p
        className='text-base sm:text-lg pt-5
        text-gray-700
         text-center group-hover:text-primary'
      >
        {label}
      </p>
    </div>
  );
};

export default SessionType;
