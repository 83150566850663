import Button from 'components/Button';
import { TextInput } from 'components/Common/TextInput';
import RadioGroup from 'components/Common/RadioGroup';
import Select from 'components/Common/Select';
import Title from 'components/Title';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SurveyEnjoji, Question, QuestionValues, QuestionKey, SurveyEnjojiValues } from 'types';
import { getSchools, saveSurvey } from 'helpers/backend-helper';
import { useEffect, useMemo, useState } from 'react';
import { getGeneralQuestions, getSurveyEnjojiQuestions } from './get-questions';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { MinusCircleIcon } from '@heroicons/react/24/outline';
import NumberInput from 'components/Common/NumberInput';
import { guidId } from 'helpers/utils';
import { OptionItem } from '../../types/components/common/select';

const DEFAULT_SURVEY_ENJOJI_VALUES = {
  child: '',
  child_age: '',
  enjoji1: '',
  enjoji2: '',
  enjoji3: '',
  enjoji4: '',
  enjoji5: '',
  enjoji6: ''
} as const;

const createSurveyEnjojies = (surveyEnjojiIndex: number): SurveyEnjoji => {
  return {
    surveyEnjojiQuestions: getSurveyEnjojiQuestions(surveyEnjojiIndex),
    id: guidId()
  };
};

const validateMessage = '回答が必要です。';
const validationSchema = yup.object().shape({
  school: yup.string().required(validateMessage),
  staff_members: yup.string(),
  years_last_average: yup.string(),
  year_of_experiences: yup.string(),
  teacher_age: yup.string(),
  gray_zone_time: yup.string(),
  gray_zone_stress_level: yup.string(),
  gray_zone_comment: yup.string(),
  survey_enjojies: yup
    .array()
    .min(1, validateMessage)
    .required(validateMessage)
    .of(
      yup.object().shape({
        child: yup.string().required(validateMessage),
        child_age: yup.string().required(validateMessage),
        enjoji1: yup.string().required(validateMessage),
        enjoji2: yup.string().required(validateMessage),
        enjoji3: yup.string().required(validateMessage),
        enjoji4: yup.string().required(validateMessage),
        enjoji5: yup.string().required(validateMessage),
        enjoji6: yup.string().required(validateMessage)
      })
    )
});

const QuestionnairePage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    setValue: setFormValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<QuestionValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      school: '',
      survey_enjojies: [{ ...DEFAULT_SURVEY_ENJOJI_VALUES }],
      staff_members: undefined,
      years_last_average: undefined,
      year_of_experiences: undefined,
      teacher_age: undefined,
      gray_zone_time: undefined,
      gray_zone_stress_level: undefined,
      gray_zone_comment: undefined
    }
  });
  const [targetSchool, setTargetSchool] = useState<string>('');
  const [generalQuestions] = useState<Question[]>(getGeneralQuestions());
  const [surveyEnjojies, setSurveyEnjojies] = useState<SurveyEnjoji[]>([createSurveyEnjojies(0)]);
  const [numberOfChildren, setNumberOfChildren] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState('');
  const setValue = (name: QuestionKey, value: string) => setFormValue(name, value, { shouldValidate: true });
  const title = useMemo(() => `乳幼児の発達に関するアンケート調査 【${targetSchool}】`, [targetSchool]);
  const onError = () => {
    setErrorMessage('入力エラーがあります。必須項目を入力してください。');
  };
  const onSubmit = async (data: QuestionValues) => {
    //check duplicate child id
    const childIds = data.survey_enjojies?.map((x) => x.child);
    if (childIds && new Set(childIds).size !== childIds?.length) {
      setErrorMessage('子供のIDが重複しています。');
      return;
    }
    setErrorMessage('');
    try {
      await saveSurvey(data);
      navigate('/submit-success', { state: { title } });
    } catch (e: any) {
      const error = e.response?.data;
      if (error) {
        for (const question_key in error) {
          if (question_key === 'survey_enjojies') {
            const surveyEnjojiErrors = error[question_key];
            surveyEnjojiErrors.forEach((surveyEnjojiQuestions: [], index: number) => {
              for (const enjojiQuestionKey in surveyEnjojiQuestions) {
                const field = `survey_enjojies.${index}.${enjojiQuestionKey}` as QuestionKey;
                const surveyEnjojiQuestionErrors: [] = surveyEnjojiQuestions[enjojiQuestionKey];
                const surveyEnjojiQuestionErrorMessage = surveyEnjojiQuestionErrors
                  .map((error: string) => error)
                  .join(' ');
                setError(field, { message: surveyEnjojiQuestionErrorMessage });
              }
            });
          } else {
            const field = question_key as QuestionKey;
            const generalQuestionErrors: [] = error[question_key];
            const generalQuestionErrorMessage = generalQuestionErrors.map((error: string) => error).join(' ');
            setError(field, { message: generalQuestionErrorMessage });
          }
        }
        setErrorMessage('入力項目に不備があります。');
      } else setErrorMessage('エラーが発生しました。');
    }
  };

  const TextInputField = (question: Question, field: QuestionKey) => {
    const value = getValues(field) as string;
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(field, event.target.value);
    };

    // もし field が . で区切られている場合は、それで区切る
    // 例: survey_enjojies.0.enjoji1 ならば、 survey_enjojies と 0 と enjoji1 に分割
    const fieldSplit = field.split('.');
    let errorMessage = errors[field]?.message;
    if (fieldSplit.length === 3 && fieldSplit[0] === 'survey_enjojies') {
      // 例: survey_enjojies.0.enjoji1 の場合
      const [surveyEnjojiField, index, questionField] = fieldSplit;
      const name = questionField as keyof SurveyEnjojiValues;
      const surveyEnjojiErrors = errors[surveyEnjojiField];
      if (surveyEnjojiErrors) {
        const surveyEnjojiError = surveyEnjojiErrors[Number(index)];
        if (surveyEnjojiError) {
          errorMessage = surveyEnjojiError[name]?.message;
        }
      }
    }
    return (
      <TextInput
        label=''
        value={value}
        onChange={handleOnChange}
        {...question.inputProps}
        className='w-full'
        errorMessage={errorMessage}
      />
    );
  };

  const RadioGroupField = (question: Question, field: QuestionKey) => {
    const value = getValues(field) as string;
    const handleOnChange = (value: string) => {
      setValue(field, value);
    };
    return (
      <RadioGroup
        value={value}
        onChange={handleOnChange}
        options={question.options!}
        errorMessage={errors[field]?.message}
      />
    );
  };

  const SelectField = (question: Question, field: QuestionKey) => {
    const value = getValues(field) as string;

    const handleOnChange = (option: OptionItem) => {
      setValue(field, option.value);
    };

    // もし field が . で区切られている場合は、それで区切る
    // 例: survey_enjojies.0.enjoji1 ならば、 survey_enjojies と 0 と enjoji1 に分割
    const fieldSplit = field.split('.');
    let errorMessage = errors[field]?.message;
    if (fieldSplit.length === 3 && fieldSplit[0] === 'survey_enjojies') {
      // 例: survey_enjojies.0.enjoji1 の場合
      const [surveyEnjojiField, index, questionField] = fieldSplit;
      const name = questionField as keyof SurveyEnjojiValues;
      const surveyEnjojiErrors = errors[surveyEnjojiField];
      if (surveyEnjojiErrors) {
        const surveyEnjojiError = surveyEnjojiErrors[Number(index)];
        if (surveyEnjojiError) {
          errorMessage = surveyEnjojiError[name]?.message;
        }
      }
    }
    return <Select value={value} onChange={handleOnChange} options={question.options!} errorMessage={errorMessage} />;
  };

  const TextAreaField = (question: Question, field: QuestionKey) => {
    const value = getValues(field) as string;
    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(field, event.target.value);
    };
    return <TextInput value={value} onChange={handleOnChange} {...question.inputProps} className='w-full' />;
  };
  const renderAnswerComponent = (question: Question) => {
    let field = question.name! as QuestionKey;
    switch (question.answer_field_type) {
      case 'input':
        return TextInputField(question, field);
      case 'radio':
        return RadioGroupField(question, field);
      case 'select':
        return SelectField(question, field);
      case 'textarea':
        return TextAreaField(question, field);
    }
  };

  const renderSurveyEnjojiEnjojiQuestionItem = (question: Question, key: string | number) => {
    return (
      <div key={key} className='rounded-lg'>
        <div className='flex flex-row items-center gap-2 mb-4'>
          <span className='font-semibold sm:text-lg text-base'>
            {question.title} {question.required && <span className='text-red text-sm'>*</span>}
          </span>
        </div>
        {renderAnswerComponent(question)}
      </div>
    );
  };
  const renderQuestionItem = (question: Question, key: string | number, questionIndex: number) => {
    return (
      <div key={key} className='sm:p-8 p-4 rounded-lg bg-white'>
        <div className='flex flex-row items-center gap-2 mb-4'>
          <span className='p-2.5 bg-gray-light text-sm sm:text-base font-semibold rounded-lg ring-2 ring-gray-medium'>
            Q{questionIndex}
          </span>
          <span className='font-semibold sm:text-xl text-lg'>
            {question.title} {question.required && <span className='text-red text-sm'>*</span>}
          </span>
        </div>
        {renderAnswerComponent(question)}
      </div>
    );
  };

  const updateSurveyEnjojies = (nextNumberOfChildren: number) => {
    if (nextNumberOfChildren !== numberOfChildren) {
      // 子供数にあわせた質問グループを作成
      const newEnjojiQuestionSurveyEnjojies = Array.from({ length: nextNumberOfChildren }, (_, index) =>
        createSurveyEnjojies(index)
      );
      setSurveyEnjojies(newEnjojiQuestionSurveyEnjojies);

      // 子供数にあわせた質問グループの値を更新
      // add default value
      if (numberOfChildren < nextNumberOfChildren) {
        const addedSurveyEnjojiValues = [];
        for (let i = numberOfChildren; i < nextNumberOfChildren; i++) {
          addedSurveyEnjojiValues.push({ ...DEFAULT_SURVEY_ENJOJI_VALUES });
        }
        setFormValue(`survey_enjojies`, [...getValues().survey_enjojies!, ...addedSurveyEnjojiValues]);
      }
      // remove value
      if (numberOfChildren > nextNumberOfChildren) {
        const removedSurveyEnjojiValues = getValues().survey_enjojies!.filter((se, index) => {
          return index < nextNumberOfChildren;
        });
        setFormValue(`survey_enjojies`, removedSurveyEnjojiValues);
      }
    }
  };

  const handleChildNumberOnClick = (type: 'increase' | 'decrease') => {
    let nextNumberOfChildren = numberOfChildren;
    if (type === 'increase') {
      nextNumberOfChildren = numberOfChildren + 1;
    } else if (type === 'decrease') {
      if (numberOfChildren === 1) return;
      nextNumberOfChildren = numberOfChildren - 1;
    }
    setNumberOfChildren(nextNumberOfChildren);
    updateSurveyEnjojies(nextNumberOfChildren);
  };

  const handleChildNumberOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const value = event.target.value;
    let nextNumberOfChildren = parseInt(value);
    if (numberOfChildren === nextNumberOfChildren) return;
    if (isNaN(nextNumberOfChildren) || nextNumberOfChildren < 1) {
      nextNumberOfChildren = 1;
    }
    setNumberOfChildren(nextNumberOfChildren);
    updateSurveyEnjojies(nextNumberOfChildren);
  };

  const handleRemoveChild = (childIndex: number) => {
    setNumberOfChildren(numberOfChildren - 1);

    // 質問を削除
    const removedSurveyEnjojies = surveyEnjojies.filter((_, index) => index !== childIndex);
    removedSurveyEnjojies.forEach((surveyEnjoji, index) => {
      // 質問エレメントのnameをreact-hook-formで管理されている配列のindexに合わせる
      surveyEnjoji.surveyEnjojiQuestions = getSurveyEnjojiQuestions(index);
    });
    setSurveyEnjojies(removedSurveyEnjojies);

    // 回答を削除
    const removedSurveyEnjojiValues = getValues().survey_enjojies!.filter((_, index) => index !== childIndex);
    setFormValue(`survey_enjojies`, removedSurveyEnjojiValues);
  };
  useEffect(() => {
    const validateSchool = async () => {
      try {
        const response = await getSchools();
        const passwords = response.map((school) => school.password);
        const password = searchParams.get('school');
        if (!password || !passwords.includes(password)) {
          navigate('/');
        }
        const school = response.find((school) => school.password === password);
        setValue('school', school!.id.toString());
        setTargetSchool(school!.name);
      } catch (error) {
        navigate('/');
      }
    };

    validateSchool();
  }, []); // dependenciesは加えてはいけない。無限ループになる
  return (
    <div className='container mx-auto py-10 flex flex-col items-center'>
      <div className='w-[80%] md:w-full'>
        <Title title={title} />
      </div>
      <div className='my-10 md:px-10 px-4 w-full mx-auto '>
        <div className='flex flex-col gap-6 sm:gap-10 mb-10'>
          <div className='rounded-lg bg-white sm:p-8 p-4'>
            <div className='flex flex-row items-center gap-2 mb-4'>
              <span className='p-2.5 bg-gray-light text-sm sm:text-base font-semibold rounded-lg ring-2 ring-gray-medium'>
                Q1
              </span>
              <span className='font-semibold sm:text-xl text-lg'>アンケート実施希望がある子供の数</span>
            </div>
            <NumberInput
              label=''
              value={numberOfChildren.toString()}
              onChange={handleChildNumberOnChange}
              onBlur={handleChildNumberOnChange}
              spinHandler={handleChildNumberOnClick}
              className='w-full'
            />
          </div>
          {surveyEnjojies.map((group, childIndex) => {
            return (
              <div key={group.id}>
                <Disclosure defaultOpen={true}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className='flex w-full justify-between rounded-lg bg-primary text-white px-4 py-4 text-left focus:outline-none focus-visible:ring'>
                        <span>子供{childIndex + 1}</span>
                        <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5`} />
                      </Disclosure.Button>
                      <Disclosure.Panel className='px-4 pb-2 pt-4 rounded-lg bg-white'>
                        <>
                          {group.surveyEnjojiQuestions.map((question, questionIndex) => {
                            if (question.survey_enjojies) {
                              return (
                                <div className='flex flex-col bg-white sm:p-8 p-4' key={`${group.id}_${question.name}`}>
                                  <div className='flex flex-row items-center gap-2 mb-4'>
                                    <span className='p-2.5 bg-gray-light text-sm sm:text-base font-semibold rounded-lg ring-2 ring-gray-medium'>
                                      Q{childIndex * 3 + questionIndex + 1 + 1}
                                    </span>
                                    <span className='font-semibold sm:text-xl text-lg'>遠城寺式</span>
                                  </div>
                                  <div className='grid grid-cols-1 xl:grid-cols-2 sm:gap-10 gap-8'>
                                    {question.survey_enjojies.map((enjoji) => {
                                      return renderSurveyEnjojiEnjojiQuestionItem(
                                        enjoji,
                                        `${group.id}_${question.name}_${enjoji.name}`
                                      );
                                    })}
                                  </div>
                                </div>
                              );
                            }
                            return renderQuestionItem(
                              question,
                              `${group.id}_${question.name}`,
                              childIndex * 3 + questionIndex + 1 + 1
                            );
                          })}
                          {surveyEnjojies.length > 1 && (
                            <div className='flex justify-end'>
                              <div
                                onClick={() => handleRemoveChild(childIndex)}
                                className='flex items-center gap-2 cursor-pointer hover:text-primary'
                              >
                                <MinusCircleIcon width={20} height={20} />
                                <span>子供の削除</span>
                              </div>
                            </div>
                          )}
                        </>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            );
          })}

          {generalQuestions.map((question, index) => {
            return renderQuestionItem(question, question.title, 1 + surveyEnjojies.length * 3 + index + 1);
          })}
          {errorMessage.length > 0 && <div className='mt-2 text-sm text-red'>{errorMessage}</div>}
        </div>
        <div className='w-full flex justify-center'>
          <Button title='送信' onClick={handleSubmit(onSubmit, onError)} />
        </div>
      </div>
    </div>
  );
};

export default QuestionnairePage;
