import { FC, ReactNode } from 'react';

interface ButtonProps {
  title: string;
  fullWidth?: boolean;
  onClick: () => void;
  iconBtn?: ReactNode;
  disabled?: boolean;
  btnWhite?: boolean;
  contentStyle?: string;
}

const Button: FC<ButtonProps> = ({
  title,
  fullWidth,
  onClick,
  iconBtn,
  disabled = false,
  btnWhite = false,
  contentStyle
}) => {
  return (
    <button
      onClick={onClick}
      type='button'
      className={`${fullWidth && 'w-full'} ${contentStyle} rounded-md border ${
        btnWhite ? 'bg-white text-black border-gray-medium hover:bg-gray-100' : 'border-primary bg-primary text-white'
      } px-6 py-2 text-base font-medium  transition hover:bg-opacity-90
      ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
      disabled={disabled}
    >
      {title}
      {iconBtn}
    </button>
  );
};

export default Button;
