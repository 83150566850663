/* eslint-disable no-unused-vars */
import configs from '../configs';
import axios from 'axios';
import { getCookie } from './utils';

function getCSRFToken() {
  return getCookie('csrftoken');
}

const service = axios.create({
  baseURL: configs.API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

service.interceptors.request.use(
  (config) => {
    if (config.method === 'post') {
      const token = getCSRFToken();
      if (token) {
        config.headers['X-CSRFToken'] = token;
      }
    }
    config.withCredentials = true;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    // 成功したら直接dataを返す
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
