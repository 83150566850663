import React, { FC } from 'react';
import { ReactComponent as SmileSvg } from 'assets/images/smile.svg';
import { ReactComponent as CryingSvg } from 'assets/images/crying.svg';
import { ReactComponent as AngerSvg } from 'assets/images/anger.svg';
import { ReactComponent as SpeechSvg } from 'assets/images/speech.svg';
import { ReactComponent as LeavingSeatSvg } from 'assets/images/leaving_seat.svg';
import { ReactComponent as ConcentrationSvg } from 'assets/images/concentration.svg';
import { ReactComponent as InattentionSvg } from 'assets/images/inattention.svg';
import { ReactComponent as OneSessionSvg } from 'assets/images/one_session.svg';
import { ReactComponent as SeveralSessionSvg } from 'assets/images/several_session.svg';
import { ReactComponent as ParentStatusSessionSvg } from 'assets/images/parents_status.svg';
import { ReactComponent as ChildSessionSvg } from 'assets/images/child.svg';

export interface SvgProps extends React.SVGProps<SVGSVGElement> {}

export const SmileIcon: FC<SvgProps> = (props) => {
  return <SmileSvg {...props} />;
};

export const CryingIcon: FC<SvgProps> = (props) => {
  return <CryingSvg {...props} />;
};

export const AngerIcon: FC<SvgProps> = (props) => {
  return <AngerSvg {...props} />;
};

export const SpeechIcon: FC<SvgProps> = (props) => {
  return <SpeechSvg {...props} />;
};

export const LeavingSeatIcon: FC<SvgProps> = (props) => {
  return <LeavingSeatSvg {...props} />;
};

export const ConcentrationIcon: FC<SvgProps> = (props) => {
  return <ConcentrationSvg {...props} />;
};

export const InattentionIcon: FC<SvgProps> = (props) => {
  return <InattentionSvg {...props} />;
};

export const OneSessionIcon: FC<SvgProps> = (props) => {
  return <OneSessionSvg {...props} />;
};

export const ServeralSessionIcon: FC<SvgProps> = (props) => {
  return <SeveralSessionSvg {...props} />;
};

export const ParentStatusSessionIcon: FC<SvgProps> = (props) => {
  return <ParentStatusSessionSvg {...props} />;
};

export const ChildSessionIcon: FC<SvgProps> = (props) => {
  return <ChildSessionSvg {...props} />;
};

export const CheckIcon: FC<SvgProps & { checked?: boolean }> = (props) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='10' cy='10' r='9.5' fill='#F1F4FF' stroke='fillColor' />
      {props.checked && <circle cx='10' cy='10' r='5' fill='fillColor' />}
    </svg>
  );
};
