import { useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { getAuth } from '../../helpers/backend-helper';
import { Auth } from '../../types';

const NotificationForStaff = () => {
  const adminPath = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/admin' : '/admin';
  return (
    // ユーザーにスタッフ状態でログインをしていることを赤のカードで通知する
    <div className='border px-4 py-3 rounded relative' role='alert'>
      <p>
        <strong className='font-bold'>スタッフ</strong>
        <span className='block sm:inline'>でログインしています。</span>
      </p>
      <p>ASQは下書きで作成しているデータも閲覧可能です。</p>
      <p>
        ゲスト状態で見るにはシークレットモードでブラウザを立ち上げるか、
        <a href={adminPath} className={'text-blue-500'}>
          管理画面
        </a>
        からログアウトしてください。
      </p>
    </div>
  );
};
const MainLayout = () => {
  const [auth, setAuth] = useState<Auth>();

  useEffect(() => {
    async function fetchAuth() {
      try {
        const fetchedAuth = await getAuth();
        setAuth(fetchedAuth);
      } catch (e) {
        console.error('Failed to fetch auth', e);
      }
    }

    fetchAuth();
  }, []);

  const isStaff = useMemo(() => {
    if (!auth) return false;
    return auth.is_staff || auth.is_superuser;
  }, [auth]);

  return (
    <>
      {isStaff && <NotificationForStaff />}
      <Outlet />
    </>
  );
};

export default MainLayout;
