/* eslint-disable no-unused-vars */
import { ApexOptions } from 'apexcharts';
import React from 'react';
import ApexChart from 'react-apexcharts';
interface ChartProps {
  id: string;
  type: 'line' | 'bar';
  categories: any;
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  title?: string;
  xaxisTitle?: string;
  yaxisTitle?: string;
  showLegend?: boolean;
  width?: string | number;
  height?: string | number;
  stacked?: boolean;
  horizontal?: boolean;

  showXAsisLabel?: boolean;
  colors?: string[];
  yaxisTooltipFormatter?: (val: number, objChart: any) => string;
  xaxisTooltipFormatter?: (val: number, objChart: any) => string;
}

export const defaultColors = [
  '#2E93fA',
  '#66DA26',
  '#546E7A',
  '#FF5733',
  '#1ABC9C',
  // '#9B59B6',
  '#FFC300'
];

const Chart: React.FC<ChartProps> = (props) => {
  const chartOptions: ApexOptions = {
    title: {
      text: props.title,
      align: 'center',
      style: {
        fontSize: '16px',
        fontWeight: 'normal',
        fontFamily: 'inherit'
      }
    },
    colors: props.colors || defaultColors,
    stroke: {
      curve: 'smooth',
      width: props.type === 'line' ? 2 : 0
    },
    chart: {
      id: props.id,
      toolbar: {
        show: false
      },
      stacked: props.stacked
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    xaxis: {
      categories: props.categories,
      title: {
        text: props.xaxisTitle
      },
      labels: {
        show: props.showXAsisLabel
      }
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          // fontSize: '16px',
          // fontWeight: 'normal',
          // fontFamily: 'inherit'
        }
      },
      title: {
        text: props.yaxisTitle
      }
    },
    legend: {
      show: props.showLegend,
      position: 'bottom',
      fontSize: '12px',
      fontFamily: 'inherit',
      fontWeight: 'normal'
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (val, opts) => {
          return props.yaxisTooltipFormatter ? props.yaxisTooltipFormatter(val, opts) : val + '';
        }
      },
      x: {
        formatter: (val, opts) => {
          return props.xaxisTooltipFormatter ? props.xaxisTooltipFormatter(val, opts) : val + '';
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      bar: {
        horizontal: props.horizontal
      }
    }
  };

  return (
    <ApexChart
      width={props.width}
      height={props.height}
      type={props.type}
      series={props.series}
      options={chartOptions}
    />
  );
};

export default Chart;
