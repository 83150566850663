import { forwardRef } from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  label?: string;
  errorMessage?: string;
  textarea?: boolean;
  rows?: number;
}

export interface InputProps extends Omit<Props, 'type'> {}

export const TextInput = forwardRef<any, InputProps>(
  ({ value, onChange, label, placeholder, errorMessage, className, textarea, rows = 5 }, ref) => {
    const Component = textarea ? 'textarea' : 'input';
    return (
      <div className={className}>
        {label && <label className='mb-2 text-left block text-base font-medium text-black'>{label}</label>}
        <div className='relative'>
          <Component
            ref={ref}
            type='text'
            defaultValue={value}
            onChange={onChange}
            placeholder={placeholder}
            className={`w-full bg-white rounded-md border py-2 px-5 text-black outline-none transition disabled:cursor-default disabled:bg-gray-2 disabled:border-gray-2 ${
              errorMessage
                ? 'border-red focus:border-red active:border-red'
                : 'border-stroke focus:border-primary active:border-primary'
            }`}
            rows={rows}
          />
        </div>
        {errorMessage && <p className='mt-2 text-sm text-red'>{errorMessage}</p>}
      </div>
    );
  }
);
