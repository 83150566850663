import { DictionaryItem } from 'types';

export const STAFF_MEMBERS: DictionaryItem[] = [
  { key: 1, value: '~10' },
  { key: 2, value: '10-20' },
  { key: 3, value: '20-30' },
  { key: 4, value: '30~' }
];

export const YEARS_LAST_AVERAGE: DictionaryItem[] = [
  { key: 1, value: '1年以内' },
  { key: 2, value: '1~2年' },
  { key: 3, value: '2~5年' },
  { key: 4, value: '5~10年' }
];

export const YEARS_OF_EXPERENCE: DictionaryItem[] = [
  { key: 1, value: '1年以内' },
  { key: 2, value: '1~2年' },
  { key: 3, value: '2~5年' },
  { key: 4, value: '5~10年' }
];

export const TEACHER_AGE: DictionaryItem[] = [
  { key: 1, value: '20代' },
  { key: 2, value: '30代' },
  { key: 3, value: '40代' },
  { key: 4, value: '50代' },
  { key: 5, value: '60代' }
];

export const GRAY_ZONE_TIME: DictionaryItem[] = [
  { key: 1, value: '~30分/日' },
  { key: 2, value: '30-60分/日' },
  { key: 3, value: '1-2時間/日' },
  { key: 4, value: '2-3時間/日' }
];

export const GRAY_ZONE_STRESS_LEVEL: DictionaryItem[] = [
  { key: 1, value: '接し方が分からない' },
  { key: 2, value: '発達障害や接し方に関する必要な情報が見つからない' },
  { key: 3, value: '理解を深めたいが、時間がない' },
  { key: 4, value: 'その子の対応に時間がかかり、クラス運営が難しい' },
  { key: 5, value: '特に悩みは感じていない・対処できている' },
  { key: 6, value: 'その他（自由記述）' }
];

export const REGEX = {
  SPACE: /[^\s]/,
  LATLONGITUDE: /^-?([0-9]{1,3}(?:\.[0-9]{1,7})?|\.[0-9]{1,7})$/
};
